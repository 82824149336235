/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import CardSlider from '../../components/CardSlider';
import '../../../assets/css/primary.theme.css';

const IndividualDashboard = () => {
  return (
    <>
      <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
        <section>
          <div className="container-sm">
            <div className="row">
              <div className="col-12 pt-4 py-lg-8">
                <h1 className="mb-4">
                  <i className="fe fe-arrow-left mr-2" />
                  Welcome Back!
                </h1>
              </div>
              <div className="col-6 col-lg-3 p-3">
                <div className="card shadow-dark">
                  <Link
                    className="card-body p-5 lift"
                    style={{ minHeight: '13rem' }}
                    to="/"
                    replace
                  >
                    <span className="badge badge-lg badge-rounded-circle badge-primary badge-float badge-float-outside">
                      <i className="fe fe-bell"></i>
                    </span>
                    <h4 className="font-weight-bold">Report Symptoms</h4>
                    <p className="card-text-sm card-text">
                      Report your daily symptoms anonymously
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-lg-3 p-3">
                <div className="card shadow-dark">
                  <Link
                    className="card-body p-5 lift"
                    style={{ minHeight: '13rem' }}
                    to="/"
                  >
                    <span className="badge badge-lg badge-rounded-circle badge-primary badge-float badge-float-outside">
                      <i className="fe fe-bell"></i>
                    </span>
                    <h4 className="font-weight-bold">Report Temperature</h4>
                    <p className="card-text-sm card-text">
                      Report your daily temperature anonymously
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-lg-3 p-3">
                <div className="card shadow-dark">
                  <Link
                    className="card-body p-5 lift"
                    style={{ minHeight: '13rem' }}
                    to="/"
                  >
                    <h4 className="font-weight-bold">Report Test Results</h4>
                    <p className="card-text-sm card-text">
                      Recently Tested for COVID-19? Share your results
                      anonymously
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-lg-3 p-3">
                <div className="card shadow-dark">
                  <Link
                    className="card-body p-5 lift"
                    style={{ minHeight: '13rem' }}
                    to="/"
                  >
                    <h4 className="font-weight-bold">Set a reminder</h4>
                    <p className="card-text-sm card-text">
                      Customize your reminders
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <Link
              className="btn btn-primary btn-block my-4"
              to="/dashboard/codes"
            >
              <i className="fe fe-aperture mr-2" /> View your QR codes
            </Link>
            <div className="row">
              <div className="col-12 py-6 py-lg-8">
                <h4 className="card-title text-body">
                  Exposure mitigation tips
                </h4>
                <CardSlider />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default withRouter(IndividualDashboard);
