import React from 'react';
import F2 from '@antv/f2';
import _ from 'lodash';

class MutliLine extends React.Component {
  componentDidMount() {
    fetch('/data/series-line.json')
      .then((res) => res.json())
      .then((data) => {
        const chart = new F2.Chart({
          id: 'container-3',
          pixelRatio: window.devicePixelRatio,
        });
        this.chart = chart;
        chart.source(data);
        chart.scale('date', {
          type: 'timeCat',
          tickCount: 3,
        });
        chart.scale('value', {
          tickCount: 5,
        });
        chart.axis('date', {
          label: function label(text, index, total) {
            // 只显示每一年的第一天
            const textCfg = {};
            if (index === 0) {
              textCfg.textAlign = 'left';
            } else if (index === total - 1) {
              textCfg.textAlign = 'right';
            }
            return textCfg;
          },
        });
        chart.tooltip({
          custom: true, // 自定义 tooltip 内容框
          onChange: function onChange(obj) {
            const legend = chart.get('legendController').legends.top[0];
            const tooltipItems = obj.items;
            const legendItems = legend.items;
            const map = {};
            legendItems.forEach(function (item) {
              map[item.name] = _.clone(item);
            });
            tooltipItems.forEach(function (item) {
              const name = item.name;
              const value = item.value;
              if (map[name]) {
                map[name].value = value;
              }
            });
            legend.setItems(_.values(map));
          },
          onHide: function onHide() {
            const legend = chart.get('legendController').legends.top[0];
            legend.setItems(chart.getLegendItems().country);
          },
        });
        chart.line().position('date*value').color('type');
        chart.render();
      });
  }
  componentWillUnmount() {
    // Make sure to destroy Typed instance on unmounting
    // to prevent memory leaks
    this.chart.destroy();
  }
  render() {
    return <canvas id="container-3" width="400" height="260"></canvas>;
  }
}

export default MutliLine;
