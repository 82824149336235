/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import Profiles from './Profiles';
import DrawerLeft from '../components/DrawerLeft';

import Lottie from 'react-lottie';
import animation from '../../assets/animation/onboarding-step-1.json';

import '../../assets/css/primary.theme.css';

const ExampleHome = () => {
  const [open, openDrawer] = useState(false);
  const onClickDrawer = () => {
    openDrawer(!open);
  };
  return (
    <>
      <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
        <DrawerLeft
          open={open}
          onClose={onClickDrawer}
          placement="left"
          duration=".2s"
          // getContainer={null}
          width="85vw"
          handler={false}
        >
          <Profiles />
        </DrawerLeft>
        <div className="container-sm p-0 pb-4">
          <nav
            className="navbar navbar-light navbar-expand-lg border-bottom p-3"
            style={{ zIndex: 3 }}
          >
            <div
              className="avatar avatar-lg avatar-online"
              onClick={() => openDrawer(true)}
            >
              <span className="avatar-title bg-primary rounded">
                <i className="fe fe-lock" />
              </span>
            </div>
          </nav>
          <section className="pt-4 pt-md-8">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-8 col-md-5 col-lg-6 order-md-2"
                  style={{ margin: '0 auto' }}
                >
                  <Lottie
                    options={{
                      animationData: animation,
                    }}
                  />
                </div>
                <div className="col-12 col-md-7 col-lg-6 order-md-1">
                  <h2 className="display-4 text-center text-md-left">
                    Welcome to <span className="text-primary">Plinks</span>
                  </h2>
                  <div className="text-center pb-4">
                    <span className="badge badge-pill badge-primary-soft text-center">
                      <span className="h6 text-uppercase font-weight-bold">
                        DEMONSTRATION
                      </span>
                    </span>
                  </div>
                  <p className="lead text-center text-md-left text-muted mb-6 mb-lg-8">
                    Get notified on your daily estimated exposure to COVID-19.
                    Gain insight into how you affect your community. Go forth
                    and propser.
                  </p>
                  <div className="text-center text-md-left">
                    <a
                      href="overview.html"
                      className="btn btn-primary btn-block shadow lift mr-1"
                    >
                      Check-in
                      <i className="fe fe-arrow-right d-none d-md-inline ml-3" />
                    </a>
                    <a
                      href="docs/index.html"
                      className="btn btn-primary-soft btn-block lift"
                    >
                      Scan
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default withRouter(ExampleHome);
