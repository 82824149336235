/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React, { useState } from 'react';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import '../../../assets/css/primary.theme.css';

const Start = ({ nextStep, isLast = false }) => {
  const history = useHistory();
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-8 col-lg-6 py-8 py-md-11">
              <div className="row">
                <div className="col-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">Start</li>
                    <li className="breadcrumb-item text-muted">Info</li>
                    <li className="breadcrumb-item text-muted">Generate</li>
                  </ol>
                </div>
              </div>

              <h2 className="font-weight-bold">Let's get started</h2>
              <h4 className="mb-6">
                You were invited by&nbsp;
                <span className="badge badge-pill badge-primary-soft">
                  <span className="h6 text-uppercase font-weight-bold">
                    Plink Technologies
                  </span>
                </span>
                &nbsp;to create an account as a&nbsp;
                <span className="badge badge-pill badge-primary-soft">
                  <span className="h6 text-uppercase font-weight-bold">
                    Administrative User
                  </span>
                </span>
              </h4>
              <form className="mb-6">
                <div className="form-label-group">
                  <input
                    type="text"
                    className="form-control form-control-flush"
                    placeholder="Enter your Email"
                    value="john@plinks.io"
                  />
                  <label htmlFor="cardEmail">Email</label>
                </div>
                <div className="form-label-group">
                  <input
                    type="password"
                    className="form-control form-control-flush"
                    id="cardPassword"
                    placeholder="Password"
                  />
                  <label htmlFor="cardPassword">Password</label>
                </div>
                <div className="form-label-group">
                  <input
                    type="password"
                    className="form-control form-control-flush"
                    id="cardPassword"
                    placeholder="Confirm Password"
                  />
                  <label htmlFor="cardPassword">Confirm Password</label>
                </div>
                <div className="row mt-6">
                  <div className="col">
                    <button
                      className="btn lift"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <i className="fe fe-arrow-left mr-2" />
                      Back
                    </button>
                  </div>
                  <div className="col-auto">
                    <button
                      className="btn btn-block btn-primary lift"
                      onClick={() => {
                        if (!isLast) {
                          history.push(nextStep);
                        }
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Setup = ({ nextStep, isLast = false }) => {
  //const history = useHistory();
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-8 col-lg-6 py-8 py-md-11">
              <div className="row">
                <div className="col-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item text-muted">Start</li>
                    <li className="breadcrumb-item">Info</li>
                    <li className="breadcrumb-item text-muted">Generate</li>
                  </ol>
                </div>
              </div>
              <h2 className="mb-6 font-weight-bold">
                <i className="fe fe-arrow-left mr-2" />
                Just so you know
              </h2>
              <p className="mb-6  text-muted">
                This account is never tied with your anonymous device profile.
                You will be able to continue to do anonymously:
              </p>
              <div className="d-flex">
                <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                  <i className="fe fe-check" />
                </div>
                <p>Scan, check-in, form a plink</p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                  <i className="fe fe-check" />
                </div>
                <p>Submit temperature report</p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                  <i className="fe fe-check" />
                </div>
                <p>Submit symptom reports</p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                  <i className="fe fe-check" />
                </div>
                <p>Receive notification</p>
              </div>
              <p className="mb-6  text-muted">
                Creating an account with this organization will allow you to
                access medial administrative functions. These functions are only
                associated with your organization profile.
              </p>
              <Link
                className="btn btn-block btn-primary mt-6 lift"
                to="/dashboard/medical"
                // onClick={() => {
                //   if (!isLast) {
                //     history.push(nextStep);
                //   }
                // }}
              >
                Continue
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Generate = ({ nextStep, isLast = false }) => {
  const history = useHistory();
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-8 col-lg-6 py-8 py-md-11">
              <div className="row">
                <div className="col-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item text-muted">Start</li>
                    <li className="breadcrumb-item text-muted">Setup</li>
                    <li className="breadcrumb-item">Generate</li>
                  </ol>
                </div>
              </div>
              <h2 className="mb-6 font-weight-bold">
                <i className="fe fe-arrow-left mr-2" />
                Generate your QRs
              </h2>
              <p className="mb-6  text-muted">
                Touchpoints such as the front door, cashier, loading dock are
                great places for QRs. You can add a nickname such as "Cash
                Register 1"
                <br />
                You can add additional QRs in your dashboard
              </p>
              <div className="card shadow-dark mb-4">
                <div className="card-body d-flex align-items-center">
                  <div className="row w-100 m-0">
                    <div className="col-auto p-0">
                      <div className="avatar avatar-xl">
                        <img
                          src="../../assets/img/avatars/avatar-1.jpg"
                          alt="..."
                          className="avatar-img rounded"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-label-group">
                        <input
                          type="text"
                          className="form-control form-control-flush"
                          placeholder="e.g. Cash Register 1"
                        />
                        <label htmlFor="cardEmail">Add a nickname</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card"
                style={{
                  backgroundColor: 'transparent',
                  border: '1px #c1c1c1 dashed',
                }}
              >
                <div className="card-body text-center">
                  <button
                    className="btn btn-primary my-4"
                    onClick={() => {
                      console.log('hello');
                    }}
                  >
                    <i className="fe fe-plus mr-2" />
                    New QR Code
                  </button>
                </div>
              </div>
              <button
                className="btn btn-block btn-primary mt-6 lift"
                onClick={() => {
                  if (!isLast) {
                    history.push(nextStep);
                  }
                }}
              >
                Continue
              </button>
              <p className="mb-0 font-size-sm text-center text-muted">
                <button
                  className="btn"
                  onClick={() => {
                    if (!isLast) {
                      history.push(nextStep);
                    }
                  }}
                >
                  {' '}
                  Skip
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Print = ({ nextStep, isLast = false }) => {
  const history = useHistory();
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-8 col-lg-6 py-8 py-md-11">
              <div className="row">
                <div className="col-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item text-muted">Start</li>
                    <li className="breadcrumb-item text-muted">Setup</li>
                    <li className="breadcrumb-item">Generate</li>
                  </ol>
                </div>
              </div>
              <h2 className="mb-6 font-weight-bold">
                <i className="fe fe-arrow-left mr-2" />
                Print your QR Codes
              </h2>
              <p className="mb-6  text-muted">
                Select your printing format
                <br />
                Smaller sizes look better, but you'll need a pair of scissors
                handy
              </p>
              <form className="mb-4">
                <div className="form-selectgroup">
                  <label className="form-selectgroup-item">
                    <input
                      type="radio"
                      name="icons"
                      defaultValue="home"
                      className="form-selectgroup-input"
                      defaultChecked
                    />
                    <span className="form-selectgroup-label">
                      1 QR per page
                    </span>
                  </label>
                  <label className="form-selectgroup-item">
                    <input
                      type="radio"
                      name="icons"
                      defaultValue="user"
                      className="form-selectgroup-input"
                    />
                    <span className="form-selectgroup-label">
                      4 QR per page
                    </span>
                  </label>
                  <label className="form-selectgroup-item">
                    <input
                      type="radio"
                      name="icons"
                      defaultValue="circle"
                      className="form-selectgroup-input"
                    />
                    <span className="form-selectgroup-label">
                      9 QR per page
                    </span>
                  </label>
                </div>
              </form>
              <button
                className="btn btn-block btn-primary mt-6 lift"
                onClick={() => {
                  if (!isLast) {
                    history.push(nextStep);
                  }
                }}
              >
                Continue
              </button>
              <p className="mb-0 font-size-sm text-center text-muted">
                <button
                  className="btn"
                  onClick={() => {
                    if (!isLast) {
                      history.push(nextStep);
                    }
                  }}
                >
                  {' '}
                  I'll do this later
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Send = ({ nextStep, isLast = false }) => {
  const history = useHistory();
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-8 col-lg-6 py-8 py-md-11">
              <div className="row">
                <div className="col-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item text-muted">Start</li>
                    <li className="breadcrumb-item text-muted">Setup</li>
                    <li className="breadcrumb-item">Generate</li>
                  </ol>
                </div>
              </div>
              <h2 className="mb-6 font-weight-bold">
                <i className="fe fe-arrow-left mr-2" />
                We're all set!
              </h2>
              <p className="mb-6  text-muted">Send or download your QR codes</p>
              <form className="mb-4">
                <div className="form-label-group mb-6">
                  <input
                    type="text"
                    className="form-control form-control-flush"
                    placeholder="Send to email"
                  />
                  <label htmlFor="cardEmail">Send to email</label>
                </div>
                <Link to="/dashboard/business">Download PDF</Link>
                <button
                  className="btn btn-block btn-primary mt-6 lift"
                  onClick={() => {
                    if (!isLast) {
                      history.push('/dashboard/business');
                    }
                  }}
                >
                  Continue to Dashboard
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const MultiStepForm = ({ onUpdate, steps, root }) => {
  let { stepId } = useParams();

  const props = {
    stepId,
    nextStep: `${parseInt(stepId || 0) + 1}`,
    rootRoute: root,
    onUpdate,
  };

  return steps[parseInt(stepId) - 1](props);
};

const MedicalRegistration = () => {
  let { path } = useRouteMatch();
  const history = useHistory();
  const formInitialState = {};
  const [formData, setFormData] = useState(formInitialState);
  const registrationSteps = [
    (props) => <Start {...props} />,
    (props) => <Setup {...props} />,
    (props) => <Generate {...props} />,
    (props) => <Print {...props} />,
    (props) => <Send {...props} />,
  ];

  const onUpdate = (key, value, shouldSubmit) => {
    const data = { ...formData };
    data[key] = value;
    setFormData(data);

    // This is a temporary workaround for state management
    if (shouldSubmit) {
      onSubmit(data);
    }
  };
  const onSubmit = (data) => {
    console.log('Sumbitting report: ', data);
    submitReport(data)
      .then(() => {
        history.push('/self-reporting/thank-you');
      })
      .catch((e) => {
        console.error('Failed to submit the report: ', e);
        history.push('/self-reporting/error');
      });
  };
  return (
    <Switch>
      <Route path={`${path}/:stepId`}>
        <MultiStepForm
          onUpdate={onUpdate}
          root="/register/organization"
          steps={registrationSteps}
        />
      </Route>
    </Switch>
  );
};

export default MedicalRegistration;
