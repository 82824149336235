import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
//import QRCode from 'qrcode.react';
import { DashboardContext } from '../../../shared/DashboardContext';
import { AuthenticationContext } from '../../../shared/AuthenticationContext';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import '../../../../assets/css/primary.theme.css';

const options = [
  { value: 'grocery', label: 'John L' },
  { value: 'restaurant', label: 'Gary B' },
  { value: 'pizza-shop', label: 'Adam A' },
  { value: 'bakery', label: 'Elsie E' },
  { value: 'school', label: 'Jennifer L' },
  { value: 'office', label: 'Casey K' },
];

class Selector extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log('Option selected:', this.state.selectedOption),
    );
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
        isMulti
        placeholder="Grant access"
      />
    );
  }
}

const SiteAdminScannables = () => {
  const [open, openBottomSheet] = useState(false);

  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <DashboardContext.Consumer>
          {({ organizationId, siteId, setOrganization, setSite }) => (
            <>
              <SwipeableBottomSheet
                open={open}
                onChange={openBottomSheet.bind(this)}
                marginTop="20"
                style={{ zIndex: '10' }}
                bodyStyle={{
                  borderTopLeftRadius: '1rem',
                  borderTopRightRadius: '1rem',
                }}
                fullScreen
              >
                <div className="PrimaryTheme">
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-8 py-lg-8">
                        <h3 className="mb-4">Create New Code</h3>
                        <p className="mb-6  text-muted">
                          Touchpoints such as the front door, cashier, loading
                          dock are great places for QRs. You can add a nickname
                          such as "Cash Register 1"
                        </p>
                        <form className="mb-4">
                          <div className="form-label-group mb-6">
                            <input
                              type="text"
                              className="form-control form-control-flush"
                              placeholder="Add a nickname"
                            />
                            <label htmlFor="cardEmail">Add a nickname</label>
                          </div>
                          <Selector />
                          <button
                            className="btn btn-block btn-primary mt-6 lift"
                            onClick={() => openBottomSheet(false)}
                          >
                            Generate QR Code
                          </button>
                        </form>
                        <div className="text-center">
                          <button
                            className="btn"
                            onClick={() => openBottomSheet(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwipeableBottomSheet>
              <div
                className="PrimaryTheme"
                style={{ overflowX: 'hidden', minHeight: '100vh' }}
              >
                <section>
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-4 py-lg-8">
                        <h2 className="mb-4">
                          <Link
                            to="/dashboard/business"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <i className="fe fe-arrow-left mr-2" />
                          </Link>
                          QR Codes
                        </h2>
                        <h4>311 Winthrop Drive, Cambridge MA 02138</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 p-3">
                        <div className="card shadow-dark">
                          <div className="card-body d-flex align-items-center">
                            <div className="row w-100 m-0">
                              <div className="col-auto p-0">
                                <div className="avatar avatar-xl">
                                  <img
                                    src="../../assets/img/avatars/qr-small.jpg"
                                    alt="..."
                                    className="avatar-img rounded"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <input
                                  type="text"
                                  className="form-control form-control-flush"
                                  placeholder="QR Code Name"
                                  value="Front Door"
                                />
                              </div>
                              <div className="col-auto p-0">
                                <Link
                                  to="/dashboard/business/codes/some-code/report"
                                  className="btn lift"
                                >
                                  <i className="fe fe-arrow-right" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 pb-3 px-3">
                        <div className="card shadow-dark">
                          <div className="card-body d-flex align-items-center">
                            <div className="row w-100 m-0">
                              <div className="col-auto p-0">
                                <div className="avatar avatar-xl">
                                  <img
                                    src="../../assets/img/avatars/qr-small.jpg"
                                    alt="..."
                                    className="avatar-img rounded"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <input
                                  type="text"
                                  className="form-control form-control-flush"
                                  placeholder="QR Code Name"
                                  value="Cash Register 1"
                                />
                              </div>
                              <div className="col-auto p-0">
                                <Link
                                  to="/dashboard/business/codes/some-code/report"
                                  className="btn lift"
                                >
                                  <i className="fe fe-arrow-right" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 pb-3 px-3">
                        <div
                          className="card"
                          style={{
                            backgroundColor: 'transparent',
                            border: '1px #c1c1c1 dashed',
                          }}
                        >
                          <div className="card-body text-center">
                            <button
                              className="btn btn-primary my-4"
                              onClick={() => openBottomSheet(true)}
                            >
                              <i className="fe fe-plus mr-2" />
                              New Code for this Site
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </DashboardContext.Consumer>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default withRouter(SiteAdminScannables);
