/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import BarChart from '../../../charts/BarChart';

import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import Select from 'react-select';

import { DashboardContext } from '../../../shared/DashboardContext';
import { AuthenticationContext } from '../../../shared/AuthenticationContext';

import '../../../../assets/css/primary.theme.css';

const options = [
  { value: 'grocery', label: 'Member' },
  { value: 'restaurant', label: 'Admin' },
];

class RoleSelect extends React.Component {
  state = {
    selectedOption: options[0],
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log('Option selected:', this.state.selectedOption),
    );
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
      />
    );
  }
}

const SiteAdminMember = () => {
  const [open, openBottomSheet] = useState(false);
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <DashboardContext.Consumer>
          {({ organizationId, siteId, setOrganization, setSite }) => (
            <>
              <SwipeableBottomSheet
                open={open}
                onChange={openBottomSheet.bind(this)}
                marginTop="20"
                style={{ zIndex: '10' }}
                bodyStyle={{
                  borderTopLeftRadius: '1rem',
                  borderTopRightRadius: '1rem',
                }}
                fullScreen
              >
                <div className="PrimaryTheme">
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-8 py-lg-8">
                        <h3 className="mb-4">Recommendedations</h3>
                        <p className="mb-6  text-muted">
                          Touchpoints such as the front door, cashier, loading
                          dock are great places for QRs. You can add a nickname
                          such as "Cash Register 1"
                        </p>
                        <h3 className="mb-4">How is exposure calculated?</h3>
                        <p className="mb-6  text-muted">
                          Touchpoints such as the front door, cashier, loading
                          dock are great places for QRs. You can add a nickname
                          such as "Cash Register 1"
                        </p>
                        <button
                          className="btn btn-primary btn-block my-4"
                          onClick={() => openBottomSheet(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </SwipeableBottomSheet>
              <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
                <section>
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-4 py-lg-8">
                        <div className="row">
                          <div className="col">
                            <h2 className="mb-4">
                              <Link
                                to="/dashboard/business/some-site/members"
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit',
                                }}
                              >
                                <i className="fe fe-arrow-left mr-2" />
                              </Link>
                              John Loew
                            </h2>
                          </div>
                          <div className="col-auto">
                            <span className="badge badge-pill badge-primary-soft">
                              <span className="h6 text-uppercase font-weight-bold">
                                Role: Member
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 py-4">
                        <div className="row">
                          <div className="col">
                            <h3 className="card-title text-body">
                              Functions performed
                            </h3>
                          </div>
                          <div className="col-auto">
                            <i
                              className="fe fe-info"
                              onClick={() => openBottomSheet(true)}
                            />
                          </div>
                        </div>
                        <div className="form-selectgroup">
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="daily"
                              className="form-selectgroup-input"
                            />
                            <span className="form-selectgroup-label">
                              Daily
                            </span>
                          </label>
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="weekly"
                              className="form-selectgroup-input"
                              defaultChecked
                            />
                            <span className="form-selectgroup-label">
                              Weekly
                            </span>
                          </label>
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="monthly"
                              className="form-selectgroup-input"
                            />
                            <span className="form-selectgroup-label">
                              Monthly
                            </span>
                          </label>
                        </div>
                        <BarChart />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 py-4">
                        <div className="row">
                          <div className="col">
                            <h3 className="card-title text-body">
                              Roles and Permissions
                            </h3>
                          </div>
                          <div className="col-auto">
                            <i
                              className="fe fe-info"
                              onClick={() => openBottomSheet(true)}
                            />
                          </div>
                        </div>
                        <RoleSelect />
                        <div className="custom-control custom-switch mt-4">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                            defaultChecked
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch1"
                          >
                            Can Create new QR for Site
                          </label>
                        </div>
                        <div className="custom-control custom-switch mt-4">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                            defaultChecked
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch1"
                          >
                            Can Invite new Member to Site
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 py-4">
                        <div className="row">
                          <div className="col">
                            <h3 className="card-title text-body">
                              QR Code Access
                            </h3>
                          </div>
                          <div className="col-auto">
                            <i
                              className="fe fe-info"
                              onClick={() => openBottomSheet(true)}
                            />
                          </div>
                        </div>
                        <div className="card shadow-dark">
                          <div className="card-body d-flex align-items-center">
                            <div className="row w-100 m-0">
                              <div className="col-auto p-0">
                                <div className="avatar avatar-xl">
                                  <img
                                    src="../../../../assets/img/avatars/qr-small.jpg"
                                    alt="..."
                                    className="avatar-img rounded"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-label-group">
                                  <input
                                    type="text"
                                    className="form-control form-control-flush"
                                    placeholder="e.g. Cash Register 1"
                                    value="Cash Register 1"
                                  />
                                  <label htmlFor="cardEmail">
                                    Edit nickname
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card"
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px #c1c1c1 dashed',
                      }}
                    >
                      <div className="card-body text-center">
                        <button
                          className="btn btn-primary my-4"
                          onClick={() => {
                            console.log('hello');
                          }}
                        >
                          <i className="fe fe-plus mr-2" />
                          Add QR Access
                        </button>
                      </div>
                    </div>
                    <button
                      className="btn btn-outline-danger btn-block my-4"
                      onClick={() => {
                        plink();
                      }}
                    >
                      Remove Member
                    </button>
                  </div>
                </section>
              </div>
            </>
          )}
        </DashboardContext.Consumer>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default withRouter(SiteAdminMember);
