/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
//import axios from 'axios';

import Heatmap from '../../../charts/Heatmap';
// import HeatmapCircular from '../charts/HeatmapCircular';
// import MultiLine from '../charts/MultiLine';
import BarChart from '../../../charts/BarChart';
import { DashboardContext } from '../../../shared/DashboardContext';
import { AuthenticationContext } from '../../../shared/AuthenticationContext';
import CardSlider from '../../../components/CardSlider';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

import '../../../../assets/css/primary.theme.css';

const SiteMemberDashboard = () => {
  const [open, openBottomSheet] = useState(false);
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <DashboardContext.Consumer>
          {({ organizationId, siteId, setOrganization, setSite }) => (
            <>
              <SwipeableBottomSheet
                open={open}
                onChange={openBottomSheet.bind(this)}
                marginTop="20"
                style={{ zIndex: '10' }}
                bodyStyle={{
                  borderTopLeftRadius: '1rem',
                  borderTopRightRadius: '1rem',
                }}
                fullScreen
              >
                <div className="PrimaryTheme">
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-8 py-lg-8">
                        <h3 className="mb-4">Recommendations</h3>
                        <p className="mb-6  text-muted">
                          Touchpoints such as the front door, cashier, loading
                          dock are great places for QRs. You can add a nickname
                          such as "Cash Register 1"
                        </p>
                        <h3 className="mb-4">How is exposure calculated?</h3>
                        <p className="mb-6  text-muted">
                          Touchpoints such as the front door, cashier, loading
                          dock are great places for QRs. You can add a nickname
                          such as "Cash Register 1"
                        </p>
                        <button
                          className="btn btn-primary btn-block my-4"
                          onClick={() => openBottomSheet(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </SwipeableBottomSheet>
              <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
                <section>
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-4 py-lg-8">
                        <div className="row">
                          <div className="col">
                            <h2 className="mb-4">
                              <Link
                                to="/dashboard/business/codes"
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit',
                                }}
                              >
                                <i className="fe fe-arrow-left mr-2" />
                              </Link>
                              Welcome Back!
                            </h2>
                          </div>
                          <div className="col-auto">
                            <span className="badge badge-pill badge-primary-soft">
                              <span className="h6 text-uppercase font-weight-bold">
                                Member view
                              </span>
                            </span>
                          </div>
                        </div>
                        <h4 className="text-muted">
                          311 Winthrop Drive, Cambridge MA 02138
                        </h4>
                      </div>
                      <div className="col-6 col-lg-3 py-3 pl-3 pr-0">
                        <div className="card shadow-dark">
                          <Link
                            className="card-body p-5 lift"
                            style={{ minHeight: '6.5rem' }}
                            to="/dashboard/member/codes"
                          >
                            <i className="fe fe-copy" />
                            <p className="card-text-sm card-text">
                              Display QR codes
                            </p>
                            <button
                              className="btn btn-primary btn-block btn-sm"
                              style={{ fontSize: '0.8rem' }}
                            >
                              View
                            </button>
                          </Link>
                        </div>
                        <div className="card bg-primary text-white shadow-dark mt-3">
                          <Link
                            className="card-body p-5 lift"
                            style={{ minHeight: '6.5rem' }}
                            to="/scan"
                          >
                            <i className="fe fe-aperture" />
                            <p className="card-text-sm card-text">
                              Scan as a QR
                            </p>
                            <button
                              className="btn btn-light btn-block btn-sm"
                              style={{
                                fontSize: '0.8rem',
                                backgroundColor: 'rgba(255, 255, 255, 0.48)',
                              }}
                            >
                              Scan
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="col-6 col-lg-3 p-3">
                        <div className="card shadow-dark">
                          <Link
                            className="card-body p-5 lift"
                            style={{ minHeight: '6.5rem' }}
                            to="/dashboard/business"
                          >
                            <i className="fe fe-thermometer" />
                            <p className="card-text-sm card-text">
                              Submit temperature
                            </p>
                            <button
                              className="btn btn-primary btn-block btn-sm"
                              style={{ fontSize: '0.8rem' }}
                            >
                              Start
                            </button>
                          </Link>
                        </div>
                        <div className="card shadow-dark mt-3">
                          <Link
                            className="card-body p-5 lift"
                            style={{ minHeight: '6.5rem' }}
                            to="/dashboard/business"
                          >
                            <i className="fe fe-clipboard" />
                            <p className="card-text-sm card-text">
                              Submit symptoms
                            </p>
                            <button
                              className="btn btn-primary btn-block btn-sm"
                              style={{ fontSize: '0.8rem' }}
                            >
                              Start
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 py-4">
                        <h3 className="card-title text-body">
                          Overview of accessible QR code scans
                        </h3>
                        <div className="form-selectgroup">
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="daily"
                              className="form-selectgroup-input"
                            />
                            <span className="form-selectgroup-label">
                              Daily
                            </span>
                          </label>
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="weekly"
                              className="form-selectgroup-input"
                              defaultChecked
                            />
                            <span className="form-selectgroup-label">
                              Weekly
                            </span>
                          </label>
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="monthly"
                              className="form-selectgroup-input"
                            />
                            <span className="form-selectgroup-label">
                              Monthly
                            </span>
                          </label>
                        </div>
                        <BarChart />
                      </div>
                      <div className="col-12 col-lg-6 pt-4 pb-0">
                        <div className="row">
                          <div className="col">
                            <h3 className="card-title text-body mb-0">
                              QR code exposure levels
                            </h3>
                          </div>
                          <div className="col-auto">
                            <i
                              className="fe fe-info"
                              onClick={() => openBottomSheet(true)}
                            />
                          </div>
                        </div>
                        <Heatmap />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 py-6 py-lg-8">
                        <div className="row">
                          <div className="col">
                            <h3 className="card-title text-body">
                              Recommendations
                            </h3>
                          </div>
                          <div className="col-auto">
                            <i
                              className="fe fe-info"
                              onClick={() => openBottomSheet(true)}
                            />
                          </div>
                        </div>
                        <CardSlider />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </DashboardContext.Consumer>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default withRouter(SiteMemberDashboard);
