/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import { withRouter } from 'react-router-dom';

import '../../../assets/css/primary.theme.css';

const SiteAdminDashboard = () => {
  return (
    <>
      <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
        <div className="container-sm">
          <div className="row">
            <div className="col-12 pt-6 py-lg-8">
              <div className="row">
                <div className="col">
                  <h3
                    className="mb-5 font-weight-bolder"
                    onClick={() => this.openBottomSheet(true)}
                  >
                    Notifications
                    <span className="text-info"> (3 new)</span>
                  </h3>
                </div>
              </div>
              <div
                className="row p-4"
                style={{
                  backgroundColor: '#f1f0fe',
                  borderBottom: '2px solid white',
                }}
              >
                <div className="col">
                  Your exposure score has recently increased. If you're feeling
                  unwell please call (887)-239-1120
                </div>
                <div className="col-auto p-0">
                  <a
                    href="/dashboard/business/codes/some-code/report"
                    className="btn lift"
                  >
                    <i className="fe fe-arrow-right" />
                  </a>
                </div>
              </div>
              <div
                className="row p-4"
                style={{
                  backgroundColor: '#f1f0fe',
                  borderBottom: '2px solid white',
                }}
              >
                <div className="col">
                  Daily reminder to take a symptom survey, even if you are
                  feeling good!
                </div>
                <div className="col-auto p-0">
                  <a
                    href="/dashboard/business/codes/some-code/report"
                    className="btn lift"
                  >
                    <i className="fe fe-arrow-right" />
                  </a>
                </div>
              </div>
              <div
                className="row p-4"
                style={{
                  backgroundColor: '#f1f0fe',
                  borderBottom: '2px solid white',
                }}
              >
                <div className="col">
                  Daily reminder to take a symptom survey, even if you are
                  feeling good!
                </div>
                <div className="col-auto p-0">
                  <a
                    href="/dashboard/business/codes/some-code/report"
                    className="btn lift"
                  >
                    <i className="fe fe-arrow-right" />
                  </a>
                </div>
              </div>
              {/* <h2 className="mt-8 font-weight-bolder">
                Applied Workflows
                </h2>
                <p className="mb-4 text-muted small">
                These are the current workflows applied by the Ministry
                of Health:
                </p>
                <hr />
                <p className="mb-4">
                When the QR of an&nbsp;
                <span className="badge badge-pill badge-info-soft">
                    office space
                </span>
                &nbsp;is scanned by&nbsp;
                <span className="badge badge-pill badge-info-soft">
                    anyone
                </span>
                ,&nbsp;
                <span className="badge badge-pill badge-info-soft">
                    display
                </span>
                &nbsp;details on social distancing.
                </p>
                <hr />
                <p className="mb-4">
                When the QR of a&nbsp;
                <span className="badge badge-pill badge-info-soft">
                    restaurant
                </span>
                &nbsp;is scanned by&nbsp;
                <span className="badge badge-pill badge-info-soft">
                    non-members
                </span>
                ,&nbsp;
                <span className="badge badge-pill badge-info-soft">
                    display
                </span>
                &nbsp;request to use a take out order.
                </p>
                <hr />
                <p className="mb-4">
                When the QR of an&nbsp;
                <span className="badge badge-pill badge-info-soft">
                    elderly home
                </span>
                &nbsp;is scanned by&nbsp;
                <span className="badge badge-pill badge-info-soft">
                    non-members
                </span>
                ,&nbsp;
                <span className="badge badge-pill badge-info-soft">
                    display
                </span>
                &nbsp;warning and recommendation of no entry.
                </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SiteAdminDashboard);
