import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import QRCode from 'qrcode.react';

import '../../../assets/css/primary.theme.css';

const IndividualScannables = () => {
  return (
    <>
      <div
        className="PrimaryTheme"
        style={{ overflowX: 'hidden', minHeight: '100vh' }}
      >
        <section>
          <div className="container-sm">
            <div className="row">
              <div className="col-12 pt-4 py-lg-8">
                <h1 className="mb-4">
                  <Link
                    to="/dashboard"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <i className="fe fe-arrow-left mr-2" />
                  </Link>
                  Your Codes
                </h1>
              </div>
              <div className="col-12 col-lg-6 p-3">
                <div className="card shadow-dark">
                  <div
                    className="card-body p-5 text-center"
                    style={{ minHeight: '20rem' }}
                  >
                    <div className="dimmer active">
                      <div className="loader">
                        <div className="p-5 pt-8">
                          <i
                            className="fe fe-shield"
                            style={{ fontSize: '2rem' }}
                          ></i>
                          <h4 className="font-weight-bold">Primary Code</h4>
                          <p className="card-text-sm card-text">
                            Only reveal this code when forming a plink. Do not
                            send this code to others.
                          </p>
                          <button
                            className="btn btn-primary my-4"
                            onClick={() => {
                              console.log('hello');
                            }}
                          >
                            Ok, I understand
                          </button>
                        </div>
                      </div>
                      <div className="dimmer-content">
                        <QRCode
                          value={
                            'http://picturesofpeoplescanningqrcodes.tumblr.com/'
                          }
                          size={250}
                          bgColor={'#ffffff'}
                          fgColor={'#000000'}
                          level={'L'}
                          includeMargin={true}
                          renderAs={'svg'}
                          imageSettings={{
                            src: 'https://static.zpao.com/favicon.png',
                            x: null,
                            y: null,
                            height: 24,
                            width: 24,
                            excavate: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 p-3">
                <div className="card shadow-dark">
                  <div className="card-body d-flex align-items-center">
                    <div className="row w-100 m-0">
                      <div className="col-auto p-0">
                        <div className="avatar avatar-xl">
                          <img
                            src="../assets/img/avatars/qr-small.jpg"
                            alt="..."
                            className="avatar-img rounded"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control form-control-flush"
                          placeholder="QR Code Name"
                        />
                      </div>
                      <div className="col-auto p-0">
                        <Link to="/dashboard/secondary" className="btn lift">
                          <i className="fe fe-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 pb-3 px-3">
                <div className="card shadow-dark">
                  <div className="card-body d-flex align-items-center">
                    <div className="row w-100 m-0">
                      <div className="col-auto p-0">
                        <div className="avatar avatar-xl">
                          <img
                            src="../assets/img/avatars/qr-small.jpg"
                            alt="..."
                            className="avatar-img rounded"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control form-control-flush"
                          placeholder="QR Code Name"
                        />
                      </div>
                      <div className="col-auto p-0">
                        <Link to="/dashboard/secondary" className="btn lift">
                          <i className="fe fe-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 pb-3 px-3">
                <div
                  className="card"
                  style={{
                    backgroundColor: 'transparent',
                    border: '1px #c1c1c1 dashed',
                  }}
                >
                  <div className="card-body text-center">
                    <button
                      className="btn btn-primary my-4"
                      onClick={() => {
                        console.log('hello');
                      }}
                    >
                      <i className="fe fe-plus mr-2" />
                      New Secondary Code
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default withRouter(IndividualScannables);
