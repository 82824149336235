import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import HomePage from './components/pages/Home';
import ScanResultPage from './components/pages/Scan/Result';
import ScannerPage from './components/pages/Scan/Scanner';
import NotFoundPage from './components/pages/NotFound';
import SignupPage from './components/pages/Auth/Signup';
import SigninPage from './components/pages/Auth/Signin';
import ResetPasswordPage from './components/pages/ResetPassword';

import IndividualDashboardPage from './components/pages/Individual/Dashboard';
import IndividualScannablesPage from './components/pages/Individual/Scannables';
import IndividualNotificationsPage from './components/pages/Individual/Notifications';
import IndividualMeasuresPage from './components/pages/Individual/Measures';

import OrganizationRegistrationPage from './components/pages/Organization/Registration';
import SitesPage from './components/pages/Organization/Sites';
import SiteDashboardPage from './components/pages/Site/Admin/Dashboard';
import SiteScannablesPage from './components/pages/Site/Admin/Scannables';
import SiteScannablePage from './components/pages/Site/Admin/Scannable';
import MembersPage from './components/pages/Site/Admin/Members';
import MemberPage from './components/pages/Site/Admin/Member';
import MemberRegistrationPage from './components/pages/Site/Member/Registration';
import MemberDashboardPage from './components/pages/Site/Member/Dashboard';
import MemberScannablesPage from './components/pages/Site/Member/Scannables';

import MedicalRegistrationPage from './components/pages/Medical/Registration';
import MedicalDashboardPage from './components/pages/Medical/Dashboard';
import MedicalUpdatePage from './components/pages/Medical/Update';

import PublicHealthRegistrationPage from './components/pages/PublicHealth/Registration';

import SelfReportingPage from './components/pages/SelfReporting/Base';

// Demo
import DemoHomePage from './components/examples/Home';
import ProfilesPage from './components/examples/Profiles';
import RegisterPage from './components/examples/Registration';
import DashboardPage from './components/examples/Dashboard';
import ProtectedRoute from './components/shared/ProtectedRoute';
import DashboardRoute from './components/shared/DashboardRoute';
import { AuthenticationContextProvider } from './components/shared/AuthenticationContext';
import { DashboardContextProvider } from './components/shared/DashboardContext';

import TestDashboardPage from './components/pages/Auth/Dashboard';
import ExampleHookPage from './components/examples/ExampleHook';

export const initialState = {
  auth: {
    token: null,
    user: null,
    isAuthenticated: false,
  },
};

const App = () => {
  return (
    <AuthenticationContextProvider initialState={initialState.auth}>
      <DashboardContextProvider>
        <Router>
          <Switch>
            {/* Demo */}
            <Route exact path="/demo">
              <DemoHomePage />
            </Route>
            <Route exact path="/demo/dashboard">
              <DashboardPage />
            </Route>
            <Route path="/create">
              <RegisterPage />
            </Route>
            {/* Testing */}
            <Route path="/test/hooks">
              <ExampleHookPage />
            </Route>
            <DashboardRoute exact path="/test/auth/dashboard/">
              <TestDashboardPage />
            </DashboardRoute>
            <ProtectedRoute exact path="/test/auth">
              <TestDashboardPage />
            </ProtectedRoute>
            {/* Home */}
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/home">
              <HomePage />
            </Route>
            {/* Default Scanning */}
            <Route path="/s/:sdvid">
              <ScanResultPage />
            </Route>
            <Route path="/scan">
              <ScannerPage />
            </Route>
            {/* Individual */}
            <Route exact path="/incognito/dashboard">
              <IndividualDashboardPage />
            </Route>
            <Route exact path="/incognito/notifications">
              <IndividualNotificationsPage />
            </Route>
            <Route exact path="/incognito/measures">
              <IndividualMeasuresPage />
            </Route>
            <Route exact path="/incognito/codes">
              <IndividualScannablesPage />
            </Route>
            <Route path="/self-reporting" component={SelfReportingPage} />
            {/* Entrypoint, select organization/site */}
            <Route exact path="/profiles">
              <ProfilesPage />
            </Route>
            <Route exact path="/dashboard">
              <DashboardPage />
            </Route>
            <Route path="/signup">
              <SignupPage />
            </Route>
            <Route path="/signin">
              <SigninPage />
            </Route>
            <Route path="/login">
              <SigninPage />
            </Route>
            <Route path="/password-reset">
              <ResetPasswordPage />
            </Route>
            {/* Organizations */}
            <Route
              path="/register/organization"
              component={OrganizationRegistrationPage}
            />
            <Route exact path="/dashboard/sites">
              <SitesPage />
            </Route>
            <ProtectedRoute exact path="/dashboard/site">
              <SiteDashboardPage />
            </ProtectedRoute>
            <Route exact path="/dashboard/site/codes">
              <SiteScannablesPage />
            </Route>
            <Route exact path="/dashboard/site/codes/:code">
              <SiteScannablePage />
            </Route>
            <Route exact path="/dashboard/site/members">
              <MembersPage />
            </Route>
            <Route exact path="/dashboard/business/:site/members/:member">
              <MemberPage />
            </Route>
            {/* Members */}
            <Route path="/register/member" component={MemberRegistrationPage} />
            <Route exact path="/dashboard/site/members/codes">
              <MemberScannablesPage />
            </Route>
            <Route exact path="/dashboard/member">
              <MemberDashboardPage />
            </Route>
            {/* Medical */}
            <Route
              path="/register/medical"
              component={MedicalRegistrationPage}
            />
            <Route exact path="/dashboard/medical">
              <MedicalDashboardPage />
            </Route>
            <Route
              path="/dashboard/medical/update"
              component={MedicalUpdatePage}
            />
            {/* PHO */}
            <Route
              path="/register/public-health"
              component={PublicHealthRegistrationPage}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </DashboardContextProvider>
    </AuthenticationContextProvider>
  );
};

export default App;
