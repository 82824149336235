import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthenticationContext } from './AuthenticationContext';
import { DashboardContext } from './DashboardContext';
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const DashboardRoute = ({ children, ...rest }) => {
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <DashboardContext.Consumer>
          {({ organizationId, siteId, setOrganization, setSite }) => (
            <Route
              {...rest}
              render={({ location }) =>
                auth.isAuthenticated ? (
                  children
                ) : (
                  <Redirect
                    to={{
                      pathname: '/signin',
                      state: { from: location },
                    }}
                  />
                )
              }
            />
          )}
        </DashboardContext.Consumer>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default DashboardRoute;
