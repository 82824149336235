import React from 'react';
import Chart from './Chart';

export default function (props) {
  const creator = (chart) => {
    chart.source(props.data, {
      checkIns: {
        tickCount: 5,
      },
    });
    chart.tooltip({
      showItemMarker: false,
      onShow: function onShow(ev) {
        const items = ev.items;
        items[0].name = null;
        items[0].name = items[0].title;
      },
    });
    chart.interval().position('bucket*count').color('#335fea');
    chart.render();
  };
  //console.log('width:', window.innerWidth);
  //console.log('width:', document.body.clientWidth)
  const { width = document.body.clientWidth, height } = props;
  if (!props.data.length) {
    return null;
  }
  const data = props.data;
  return (
    <div>
      <Chart
        data={{ data, type: 'bar' }}
        width={width}
        height={height}
        creator={creator}
      />
    </div>
  );
}
