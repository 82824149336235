import React from 'react';
import Chart from './Chart';

export default function (props) {
  const creator = (chart, data) => {
    const { source, type } = data;
    chart.source(source);
    chart.scale('date', { type: 'timeCat', tickCount: 3 });
    chart.scale('value', { tickCount: 5 });
    chart.axis('date', {
      label(text, index, total) {
        //console.log(text);
        if (index === 0) {
          return { textAlign: 'left' };
        }
        if (index === total - 1) {
          return { textAlign: 'right' };
        }
        return {};
      },
    });
    chart.axis('value', {
      label(text) {
        const prefix = text >= 0 ? '' : '-';
        const value = Math.abs(text);
        if (value <= 10000) {
          return { text: prefix + value };
        }
        if (value <= 100000000) {
          return { text: `${prefix}${value / 10000} 'ten thousands'` };
        }
        return { text: `${prefix}${value / 100000000} billions` };
      },
    });
    chart.tooltip({
      custom: true,
      onChange(obj) {
        const legend = chart.get('legendController').legends.top[0];
        const tooltipItems = obj.items;
        const legendItems = legend.items;
        const map = {};

        legendItems.forEach((item) => {
          map[item.name] = JSON.parse(JSON.stringify(item));
        });
        tooltipItems.forEach(({ name, value }) => {
          if (map[name]) {
            map[name].value = Number(value).toFixed(2);
          }
        });
        legend.setItems(Object.values(map));
      },
      onHide() {
        const legend = chart.get('legendController').legends.top[0];
        legend.setItems(chart.getLegendItems().country);
      },
    });
    if (type === 'line') {
      chart.line().position('date*value').color('type');
    }
    if (type === 'bar') {
      chart.interval().position('date*value').color('type').adjust({
        type: 'dodge',
        marginRatio: 0.05,
      });
    }
    chart.render();
  };
  console.log(props);
  const { width = window.innerWidth, height } = props;
  if (!props.data.length) {
    return null;
  }
  const source = [];
  props.data.forEach(({ name, stats }) => {
    stats.forEach(({ t, v }) => {
      const date = t.split('');
      date.splice(4, 0, '-');
      date.push('-01');
      source.push({ type: name, date: date.join(''), value: v });
    });
  });
  return (
    <div>
      <Chart
        data={{ source, type: props.type }}
        width={width}
        height={height}
        creator={creator}
      />
    </div>
  );
}
