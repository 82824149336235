import React, { useReducer, createContext } from 'react';
import { ApolloConsumer } from '@apollo/react-hooks';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user,
      };
    case 'logout':
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: null,
      };
    default:
      return state;
  }
};

export const AuthenticationContext = createContext();

export const AuthenticationContextProvider = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const tokenInStorage = localStorage.getItem('authToken');
  const userInStorage = localStorage.getItem('user');

  if (tokenInStorage && userInStorage) {
    if (!state.isAuthenticated) {
      dispatch({
        type: 'login',
        payload: {
          user: JSON.parse(userInStorage),
          token: tokenInStorage,
        },
      });
    }
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <AuthenticationContext.Provider
          value={{
            ...state,
            login: (token, user) => {
              localStorage.setItem('authToken', token);
              localStorage.setItem('user', JSON.stringify(user));
              dispatch({
                type: 'login',
                payload: {
                  token,
                  user,
                },
              });
              client.resetStore();
            },
            logout: () => {
              localStorage.removeItem('authToken');
              localStorage.removeItem('user');
              dispatch({ type: 'logout' });
              client.resetStore();
            },
          }}
        >
          {children}
        </AuthenticationContext.Provider>
      )}
    </ApolloConsumer>
  );
};
