//import React from 'react'
import { gql } from 'apollo-boost';
const moment = require('moment');

export const QUERY_TYPE = {
  POINT_CHECKINS: 0,
  SITE_CHECKINS: 1,
  ORG_CHECKINS: 2,
  POINTS_CHECKINS: 3,
};

export const CHART_TIMESTEP = {
  TODAY: 0,
  THIS_WEEK: 1,
  THIS_MONTH: 2,
};

// RETURNS { loading, error, data }
export const buildQueryForChart = (queryType, chartTimeStep, id) => {
  let query;
  let idParam;
  switch (queryType) {
    case QUERY_TYPE.POINT_CHECKINS:
      query = 'plinkCountForPoint';
      idParam = 'pointId';
      break;
    case QUERY_TYPE.SITE_CHECKINS:
      query = 'plinkCountForSite';
      idParam = 'siteId';
      break;
    case QUERY_TYPE.ORG_CHECKINS:
      query = 'plinkCountForOrg';
      idParam = 'orgId';
      break;
    case QUERY_TYPE.POINTS_CHECKINS:
      query = 'plinkCountForPoints';
      idParam = 'pointIds';
      break;
    default:
      break;
  }

  let timeStep;
  let intervalBegin;
  const DAY = 24,
    HOUR = 60,
    MINUTE = 60,
    MILLI = 1000;
  switch (chartTimeStep) {
    case CHART_TIMESTEP.TODAY:
      timeStep = HOUR * MINUTE * MILLI;
      intervalBegin = moment().subtract(1, 'day');
      break;
    case CHART_TIMESTEP.THIS_WEEK:
      timeStep = DAY * HOUR * MINUTE * MILLI;
      intervalBegin = moment().subtract(1, 'week');
      break;
    case CHART_TIMESTEP.THIS_MONTH:
      timeStep = DAY * HOUR * MINUTE * MILLI;
      intervalBegin = moment().subtract(1, 'month');
      break;
    default:
      break;
  }

  let queryString = `
  query {
    ${query}(
      interval: {
          begin: "${intervalBegin
            .minute(0)
            .second(0)
            .millisecond(0)
            .toDate()
            .toISOString()}",
          end: "${moment()
            .minute(0)
            .second(0)
            .millisecond(0)
            .toDate()
            .toISOString()}"
      }
      timeStep: ${timeStep}
      ${idParam}: "${id}"
    ) {
      lowerBound
      count
    }
  }`;

  console.log(JSON.stringify(queryString));

  let siteQuery = gql`
  query {
    ${query}(
      interval: {
          begin: "${intervalBegin
            .minute(0)
            .second(0)
            .millisecond(0)
            .toDate()
            .toISOString()}",
          end: "${moment()
            .minute(0)
            .second(0)
            .millisecond(0)
            .toDate()
            .toISOString()}"
      }
      timeStep: ${timeStep}
      ${idParam}: "${id}"
    ) {
      lowerBound
      count
    }
  }`;
  console.log('Site Query: ', { siteQuery });
  return siteQuery;
};

export const mapQueryDataForChart = (data, chartTimeStep) => {
  return data[Object.keys(data)[0]].map((datum) => {
    const date = datum.lowerBound;
    let x;
    switch (chartTimeStep) {
      case CHART_TIMESTEP.TODAY:
        x = moment(date).format('HH:mm');
        break;
      case CHART_TIMESTEP.THIS_WEEK:
        x = moment(date).format('ddd');
        break;
      case CHART_TIMESTEP.THIS_MONTH:
        x = moment(date).format('d');
        break;
      default:
        throw new Error('Illegal charTimeStep!');
    }

    return { bucket: x, value: datum.count };
  });
};
