/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';

import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import BarChart from '../charts/mobile/BarChart';
import Sparkline from '../charts/Sparkline';

//import Profiles from './Profiles';
//import DrawerLeft from '../components/DrawerLeft';
import Drawer from 'rc-drawer';

import { AuthenticationContext } from '../shared/AuthenticationContext';
import { DashboardContext } from '../shared/DashboardContext';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

import '../../assets/css/primary.theme.css';

const GET_CHECKINS_COUNT = gql`
  query PlinkCountForSite(
    $interval: IntervalInput
    $timeStep: Duration
    $siteId: ID
  ) {
    plinkCountForSite(
      interval: $interval
      timeStep: $timeStep
      siteId: $siteId
    ) {
      lowerBound
      count
    }
  }
`;
const GeneratedChart = ({ siteId }) => {
  console.log(siteId);
  const { loading, error, data } = useQuery(GET_CHECKINS_COUNT, {
    variables: {
      interval: {
        begin: '2020-05-01 00:00:00.000Z',
        end: '2020-05-08 00:00:00.000Z',
      },
      timeStep: 86400000,
      siteId: '5ecd81e2b7d118ea101de8dd',
    },
  });
  if (loading) return <p>Good things take time....</p>;
  if (error) return <p>Something went wrong...</p>;
  if (data) {
    const formatted = [];
    data.plinkCountForSite.forEach((item) => {
      formatted.push({
        bucket: moment(item.lowerBound).format('ddd'),
        count: item.count,
      });
    });
    return <BarChart height={200} data={formatted} />;
  }
};

const BottomSheet = ({ isOpen, bottomSheet }) => {
  return (
    <SwipeableBottomSheet
      open={isOpen}
      onChange={bottomSheet.bind(this)}
      marginTop={20}
      style={{ zIndex: '10' }}
      bodyStyle={{
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem',
      }}
      fullScreen
    >
      <div className="PrimaryTheme">
        <div className="container-sm">
          <div className="row">
            <div className="col-12 pt-8 py-lg-8">
              <h3 className="mb-4">Recommendations</h3>
              <p className="mb-6  text-muted">
                Touchpoints such as the front door, cashier, loading dock are
                great places for QRs. You can add a nickname such as "Cash
                Register 1"
              </p>
              <h3 className="mb-4">How is exposure calculated?</h3>
              <p className="mb-6  text-muted">
                Touchpoints such as the front door, cashier, loading dock are
                great places for QRs. You can add a nickname such as "Cash
                Register 1"
              </p>
              <button
                className="btn btn-primary btn-block my-4"
                onClick={() => bottomSheet.close()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </SwipeableBottomSheet>
  );
};
const useBottomSheet = () => {
  const [isOpen, setBottomSheet] = useState(false);
  return {
    isOpen,
    bind: () => setBottomSheet.bind(this),
    close: () => setBottomSheet(false),
    open: () => setBottomSheet(true),
  };
};

const DrawerLeft = ({ isOpen, drawer }) => {
  return (
    <Drawer
      open={isOpen}
      onClose={drawer.close()}
      placement="left"
      width="90vw"
      handler={false}
    >
      {/* <Profiles /> */}
    </Drawer>
  );
};

const useDrawer = () => {
  const [isOpen, setDrawer] = useState(false);
  return {
    isOpen,
    open: () => setDrawer(true),
    close: () => setDrawer(false),
  };
};

const GreetingSection = () => {
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <div className="col-12 pt-4 py-lg-8">
          <div className="row">
            <div className="col">
              <h2 className="mb-4">
                <Link
                  to="/demo"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <i className="fe fe-arrow-left mr-2" />
                </Link>
                Welcome Back!
              </h2>
            </div>
            <div className="col-auto">
              <span className="badge badge-pill badge-primary-soft">
                <span className="h6 text-uppercase font-weight-bold">
                  {auth.isAuthenticated ? auth.user.name : 'anonymous'}!
                  {/* Admin view */}
                </span>
              </span>
            </div>
          </div>
          <h4 className="text-muted">311 Winthrop Drive, Cambridge MA 02138</h4>
        </div>
      )}
    </AuthenticationContext.Consumer>
  );
};

const CardCheckIns = () => {
  return (
    <div className="col-6 col-lg-3 py-3 pl-3 pr-0">
      <div className="card shadow-dark">
        <Link
          className="card-body p-5 lift"
          style={{ minHeight: '13rem' }}
          to="/dashboard/business/codes"
        >
          <h1 className="font-weight-bold">
            50
            <i className="fe fe-arrow-up ml-2" />
          </h1>
          <p className="card-text-sm card-text mb-0">
            Today's plinks formed at your site
          </p>
          <Sparkline />
          <button
            className="btn btn-primary btn-block btn-sm"
            style={{ fontSize: '0.8rem' }}
          >
            View QR codes
          </button>
        </Link>
      </div>
    </div>
  );
};

const CardExposure = () => {
  return (
    <div className="col-6 col-lg-3 p-3">
      <div className="card shadow-dark">
        <Link
          className="card-body p-5 lift"
          style={{ minHeight: '13rem' }}
          to="/dashboard/business/some-site/members"
        >
          <h1 className="font-weight-bold text-success">3/10</h1>
          <p className="card-text-sm card-text mb-0">
            Aggregated Member Exposure Score
          </p>
          <Sparkline />
          <button
            className="btn btn-primary btn-block btn-sm"
            style={{ fontSize: '0.8rem' }}
          >
            View members
          </button>
        </Link>
      </div>
    </div>
  );
};

const SectionCheckIns = () => {
  const [plinkChartInterval, setPlinkChartInterval] = useState('THIS_WEEK');
  return (
    <DashboardContext.Consumer>
      {({ siteId }) => (
        <div className="col-12 col-lg-6 py-4">
          <h3 className="card-title text-body">Overview of QR code scans</h3>
          <div className="form-selectgroup">
            <label className="form-selectgroup-item">
              <input
                type="radio"
                name="icons"
                defaultValue="Today"
                className="form-selectgroup-input"
                onChange={() => setPlinkChartInterval('TODAY')}
              />
              <span className="form-selectgroup-label">Today</span>
            </label>
            <label className="form-selectgroup-item">
              <input
                type="radio"
                name="icons"
                defaultValue="thisWeek"
                className="form-selectgroup-input"
                onChange={() => setPlinkChartInterval('THIS_WEEK')}
                defaultChecked
              />
              <span className="form-selectgroup-label">This Week</span>
            </label>
            <label className="form-selectgroup-item">
              <input
                type="radio"
                name="icons"
                defaultValue="thisMonth"
                className="form-selectgroup-input"
                onChange={() => setPlinkChartInterval('THIS_MONTH')}
              />
              <span className="form-selectgroup-label">This Month</span>
            </label>
          </div>
          <GeneratedChart siteId={siteId} interval={plinkChartInterval} />
        </div>
      )}
    </DashboardContext.Consumer>
  );
};

const Dashboard = () => {
  const bottomSheet = useBottomSheet();
  const drawer = useDrawer();
  return (
    <>
      <BottomSheet isOpen={bottomSheet.isOpen} bottomSheet={bottomSheet} />
      <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
        <DrawerLeft isOpen={drawer.isOpen} drawer={drawer} />
        <section>
          <div className="container-sm">
            <div className="row">
              <GreetingSection />
              <CardCheckIns />
              <CardExposure />
            </div>
            <div className="row">
              <SectionCheckIns />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default withRouter(Dashboard);
