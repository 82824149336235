/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import '../../assets/css/primary.theme.css';

const NotFound = () => {
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">
              <h1 className="display-3 font-weight-bold text-center">404</h1>

              <p className="mb-5 text-center text-muted">
                Looks like what you're looking for doesn't exist. Drop us a line
                at start@plinks.io if there's an issue!
              </p>

              <div className="text-center">
                <a className="btn btn-primary" href="/">
                  Back to home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
