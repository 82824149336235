import React, { useState } from 'react';
import { SurveyLayout, SurveyNavigation } from '../../components/Survey/Layout';
import {
  CheckboxQuestion,
  RadioButtonQuestion,
  DayPickerQuestion,
  TextFieldAndRadioQuestion,
} from '../../components/Survey/Questions';

/**
 * Steps used in the Self Reporting Pages. Contains survey components
 * that render the question and options, as well as the nextStep link.
 * Todo: inject onUpdate function that updates the form state
 * @param onUpdate: function
 * @param nextStep: string
 * @returns {Styled Component}
 * @constructor
 */

export const PlanningStep1 = ({ onUpdate, nextStep }) => {
  const [testPlanDay, setTestPlanDay] = useState();
  const question =
    'If you would like, you can let us know when you will be tested, and we will set a reminder notification, so you can update us on the result';
  return (
    <SurveyLayout>
      <DayPickerQuestion
        question={question}
        value={testPlanDay}
        onChange={(value) => {
          setTestPlanDay(value);
        }}
      />
      <SurveyNavigation
        nextStep={nextStep}
        nextBtnEnabled={testPlanDay}
        onNextBtnClick={() => {
          onUpdate('testPlanDay', testPlanDay);
        }}
      />
    </SurveyLayout>
  );
};

export const WasTestedStep1 = ({ onUpdate, nextStep }) => {
  const question = 'When have you been tested for COVID-19';
  const [testedOn, setTestedOn] = useState();
  return (
    <SurveyLayout>
      <DayPickerQuestion
        question={question}
        value={testedOn}
        onChange={(value) => {
          setTestedOn(value);
        }}
      />
      <SurveyNavigation
        nextStep={nextStep}
        nextBtnEnabled={testedOn}
        onNextBtnClick={() => {
          onUpdate('testedOn', testedOn);
        }}
      />
    </SurveyLayout>
  );
};

export const WasTestedStep2 = ({ onUpdate, nextStep }) => {
  const [result, setResult] = useState();
  const question = 'What was the result of your test?';
  const options = [
    {
      value: 'true',
      label: 'I was tested positive.',
    },
    {
      value: 'false',
      label: 'I was tested negative.',
    },
  ];
  return (
    <SurveyLayout>
      <RadioButtonQuestion
        question={question}
        options={options}
        value={result}
        onChange={(value) => {
          setResult(value);
        }}
      />
      <SurveyNavigation
        nextStep={nextStep}
        nextBtnEnabled={result}
        onNextBtnClick={() => {
          onUpdate('result', result, true);
        }}
        isLast={true}
      />
    </SurveyLayout>
  );
};

export const NotSureStep1 = ({ onUpdate, nextStep }) => {
  const [age, setAge] = useState();
  const question = 'How old are you?';
  const options = [
    {
      value: 'UNDERAGE',
      label: 'I am under 18',
    },
    {
      value: 'GENERAL',
      label: 'I am between 18 and 64',
    },
    {
      value: 'ELDERLY',
      label: 'I am older than 64',
    },
  ];
  return (
    <SurveyLayout>
      <RadioButtonQuestion
        question={question}
        options={options}
        value={age}
        onChange={(value) => {
          setAge(value);
        }}
      />
      <SurveyNavigation
        nextStep={nextStep}
        nextBtnEnabled={age}
        onNextBtnClick={() => {
          onUpdate('age', age);
        }}
        isSkippable={true}
        onSkipBtnClick={() => {
          onUpdate('age', {}, true);
        }}
      />
    </SurveyLayout>
  );
};

export const NotSureStep2 = ({ onUpdate, nextStep }) => {
  const question = 'Do you have any of these symptoms?';
  const options = [
    {
      value: 'FEVER',
      label: 'Fevers, chills, sweating',
    },
    {
      value: 'BREATHING',
      label: 'Difficulty breathing',
    },
    {
      value: 'NEW_COUGH',
      label: 'New and increasing cough',
    },
    {
      value: 'SORE_THROAT',
      label: 'Sore throat',
    },
    {
      value: 'ACHING',
      label: 'Aching throughout the body',
    },
    {
      value: 'VOMITING_DIARRHEA',
      label: 'Vomiting or diarrhea',
    },
    {
      value: 'MIGRAINES',
      label: 'Migraines',
    },
    {
      value: 'LOSS_OF_TASTE',
      label: 'Loss of taste and smell / Changes in how food tastes',
    },
    {
      value: '',
      label: 'None of the above',
    },
  ];
  const [symptoms, setSymptoms] = useState(
    options.reduce(
      (options, option) => ({
        ...options,
        [option.value]: false,
      }),
      {},
    ),
  );

  const selectedSymptoms = Object.keys(symptoms).filter((key) => symptoms[key]);

  return (
    <SurveyLayout>
      <CheckboxQuestion
        question={question}
        options={options}
        onChange={(values) => {
          setSymptoms(values);
        }}
      />
      <SurveyNavigation
        nextStep={nextStep}
        nextBtnEnabled={
          selectedSymptoms &&
          selectedSymptoms.length &&
          selectedSymptoms.length > 0
        }
        onNextBtnClick={() => {
          onUpdate('symptoms', symptoms);
        }}
      />
    </SurveyLayout>
  );
};

export const NotSureStep3 = ({ onUpdate, nextStep }) => {
  const [dailyPeopleAtHome, setDailyPeopleAtHome] = useState();
  const question = 'On a daily basis with how many people are in your home?';
  const options = [
    {
      value: 'SINGLE',
      label: '1',
    },
    {
      value: 'PARTNER',
      label: '2',
    },
    {
      value: 'SMALL',
      label: '3-5',
    },
    {
      value: 'MEDIUM',
      label: '6-10',
    },
    {
      value: 'LARGE',
      label: '10+',
    },
  ];
  return (
    <SurveyLayout>
      <RadioButtonQuestion
        question={question}
        options={options}
        value={dailyPeopleAtHome}
        onChange={(value) => {
          setDailyPeopleAtHome(value);
        }}
      />
      <SurveyNavigation
        nextStep={nextStep}
        nextBtnEnabled={dailyPeopleAtHome}
        onNextBtnClick={() => {
          onUpdate('dailyPeopleAtHome', dailyPeopleAtHome);
        }}
      />
    </SurveyLayout>
  );
};

export const NotSureStep4 = ({ onUpdate, nextStep }) => {
  const [dailyContacts, setDailyContacts] = useState();
  const question =
    'On a daily basis, with how many people are you in contact with outside your home?';
  const options = [
    {
      value: 'NONE',
      label: 'None',
    },
    {
      value: 'SINGLE',
      label: '1',
    },
    {
      value: 'SMALL',
      label: '2-4',
    },
    {
      value: 'MEDIUM',
      label: '5-10',
    },
    {
      value: 'LARGE',
      label: '10+',
    },
  ];
  return (
    <SurveyLayout>
      <RadioButtonQuestion
        question={question}
        options={options}
        value={dailyContacts}
        onChange={(value) => {
          setDailyContacts(value);
        }}
      />
      <SurveyNavigation
        nextStep={nextStep}
        nextBtnEnabled={dailyContacts}
        onNextBtnClick={() => {
          onUpdate('dailyContacts', dailyContacts);
        }}
      />
    </SurveyLayout>
  );
};

export const NotSureStep5 = ({ onUpdate, nextStep }) => {
  const [exposed, setExposed] = useState();
  const question =
    'Do you have reason to believe you are likely to have been exposed to COVID-19 recently?';
  const options = [
    {
      value: 'false',
      label: 'No',
    },
    {
      value: 'true',
      label: 'Yes',
    },
  ];
  return (
    <SurveyLayout>
      <RadioButtonQuestion
        question={question}
        options={options}
        value={exposed}
        onChange={(value) => {
          setExposed(value);
        }}
      />
      <SurveyNavigation
        nextStep={nextStep}
        nextBtnEnabled={exposed}
        onNextBtnClick={() => {
          onUpdate('exposed', exposed === 'true');
        }}
      />
    </SurveyLayout>
  );
};

export const NotSureStep6 = ({ onUpdate, nextStep }) => {
  const [temperature, setTemperature] = useState({
    unit: undefined,
    value: null,
  });
  const options = [
    {
      value: 'celsius',
      label: 'Celsius',
    },
    {
      value: 'fahrenheit',
      label: 'Fahrenheit',
    },
  ];
  const updateTemperature = (key, value) => {
    let updatedTemperature = { ...temperature };
    if (key === 'value') {
      // if(/*last character === k/f/c*//)
      // {
      //   updatedTemperature['units'] = /* last character value to units */
      // }
      updatedTemperature[key] = parseFloat(value);
    } else {
      updatedTemperature[key] = value;
    }
    setTemperature(updatedTemperature);
  };
  return (
    <SurveyLayout>
      <TextFieldAndRadioQuestion
        textQuestion="What is your current temperature?"
        radioQuestion="What is the temperature unit you measure in?"
        options={options}
        placeholder="Enter your temperature..."
        onChangeText={(value) => {
          updateTemperature('value', value);
        }}
        onChangeOption={(value) => {
          updateTemperature('unit', value);
        }}
      />
      <SurveyNavigation
        nextStep={nextStep}
        isSkippable={true}
        nextBtnEnabled={temperature.unit && temperature.value}
        onNextBtnClick={() => {
          onUpdate('temperature', temperature, true);
        }}
        onSkipBtnClick={() => {
          onUpdate('temperature', {}, true);
        }}
        isLast={true}
      />
    </SurveyLayout>
  );
};
