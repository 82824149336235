import React from 'react';
import Flickity from 'react-flickity-component';
import '../../assets/css/primary.theme.css';

const flickityOptions = {
  cellAlign: 'left',
  imagesLoaded: true,
  pageDots: true,
  prevNextButtons: false,
  contain: true,
};

function CardSlider() {
  return (
    <div className="PrimaryTheme">
      <Flickity
        className={'flickity-viewport-visible pt-4 pb-8'} // default ''
        elementType={'div'} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        <div className="col-10 col-md-5 col-lg-4">
          <div
            className="card card-border shadow-light-lg lift lift-lg"
            style={{ borderTopColor: '#335fea' }}
          >
            <div className="card-body text-center">
              <p className="text-gray-700 mb-5">
                Recommended cleaning procedures for office spaces
              </p>
              <a href="#!" style={{ color: '#335fea' }}>
                Read more
              </a>
            </div>
          </div>
        </div>
        <div className="col-10 col-md-5 col-lg-4">
          <div
            className="card card-border shadow-light-lg lift lift-lg"
            style={{ borderTopColor: '#335fea' }}
          >
            <div className="card-body text-center">
              <p className="text-gray-700 mb-5">
                WHO recommended guidelines for social distancing in office
                spaces
              </p>
              <a href="#!" style={{ color: '#335fea' }}>
                Read more
              </a>
            </div>
          </div>
        </div>
        <div className="col-10 col-md-5 col-lg-4">
          <div
            className="card card-border shadow-light-lg lift lift-lg"
            style={{ borderTopColor: '#335fea' }}
          >
            <div className="card-body text-center">
              <p className="text-gray-700 mb-5">
                Ministry of Health June Update: Applied health measures for
                office spaces
              </p>
              <a href="#!" style={{ color: '#335fea' }}>
                Read more
              </a>
            </div>
          </div>
        </div>
        <div className="col-10 col-md-5 col-lg-4">
          <div
            className="card card-border shadow-light-lg lift lift-lg"
            style={{ borderTopColor: '#335fea' }}
          >
            <div className="card-body text-center">
              <p className="text-gray-700 mb-5">
                {' '}
                Uses Plinks to plan their product development with engineering.{' '}
              </p>
              <a href="#!" style={{ color: '#335fea' }}>
                Read more
              </a>
            </div>
          </div>
        </div>
        <div className="col-10 col-md-5 col-lg-4">
          <div
            className="card card-border shadow-light-lg lift lift-lg"
            style={{ borderTopColor: '#335fea' }}
          >
            <div className="card-body text-center">
              <p className="text-gray-700 mb-5">
                {' '}
                Uses Plinks to share financial projections with their investors.{' '}
              </p>
              <a href="#!" style={{ color: '#335fea' }}>
                Read more
              </a>
            </div>
          </div>
        </div>
        <div className="col-10 col-md-5 col-lg-4">
          <div
            className="card card-border shadow-light-lg lift lift-lg"
            style={{ borderTopColor: '#335fea' }}
          >
            <div className="card-body text-center">
              <p className="text-gray-700 mb-5">
                {' '}
                Uses Plinks to plan their priorities for the company quarterly
                and annually.{' '}
              </p>
              <a href="#!" style={{ color: '#335fea' }}>
                Read more
              </a>
            </div>
          </div>
        </div>
      </Flickity>
    </div>
  );
}
export default CardSlider;

// class CardSlider extends React.Component {
//   render() {
//     return (
//       <div className="PrimaryTheme">
//         <div
//           className="flickity-viewport-visible pt-4 pb-8"
//           data-flickity='{"cellAlign": "left", "imagesLoaded": true, "pageDots": true, "prevNextButtons": false, "contain": true}'
//         >
//           <div className="col-10 col-md-5 col-lg-4">
//             <div
//               className="card card-border shadow-light-lg lift lift-lg"
//               style={{ borderTopColor: '#335fea' }}
//             >
//               <div className="card-body text-center">
//                 <p className="text-gray-700 mb-5">
//                   Recommended cleaning procedures for office spaces
//                 </p>
//                 <a href="#!" style={{ color: '#335fea' }}>
//                   Read more
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className="col-10 col-md-5 col-lg-4">
//             <div
//               className="card card-border shadow-light-lg lift lift-lg"
//               style={{ borderTopColor: '#335fea' }}
//             >
//               <div className="card-body text-center">
//                 <p className="text-gray-700 mb-5">
//                   WHO recommended guidelines for social distancing in office
//                   spaces
//                 </p>
//                 <a href="#!" style={{ color: '#335fea' }}>
//                   Read more
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className="col-10 col-md-5 col-lg-4">
//             <div
//               className="card card-border shadow-light-lg lift lift-lg"
//               style={{ borderTopColor: '#335fea' }}
//             >
//               <div className="card-body text-center">
//                 <p className="text-gray-700 mb-5">
//                   Ministry of Health June Update: Applied health measures for
//                   office spaces
//                 </p>
//                 <a href="#!" style={{ color: '#335fea' }}>
//                   Read more
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className="col-10 col-md-5 col-lg-4">
//             <div
//               className="card card-border shadow-light-lg lift lift-lg"
//               style={{ borderTopColor: '#335fea' }}
//             >
//               <div className="card-body text-center">
//                 <p className="text-gray-700 mb-5">
//                   {' '}
//                   Uses Plinks to plan their product development with
//                   engineering.{' '}
//                 </p>
//                 <a href="#!" style={{ color: '#335fea' }}>
//                   Read more
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className="col-10 col-md-5 col-lg-4">
//             <div
//               className="card card-border shadow-light-lg lift lift-lg"
//               style={{ borderTopColor: '#335fea' }}
//             >
//               <div className="card-body text-center">
//                 <p className="text-gray-700 mb-5">
//                   {' '}
//                   Uses Plinks to share financial projections with their
//                   investors.{' '}
//                 </p>
//                 <a href="#!" style={{ color: '#335fea' }}>
//                   Read more
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className="col-10 col-md-5 col-lg-4">
//             <div
//               className="card card-border shadow-light-lg lift lift-lg"
//               style={{ borderTopColor: '#335fea' }}
//             >
//               <div className="card-body text-center">
//                 <p className="text-gray-700 mb-5">
//                   {' '}
//                   Uses Plinks to plan their priorities for the company quarterly
//                   and annually.{' '}
//                 </p>
//                 <a href="#!" style={{ color: '#335fea' }}>
//                   Read more
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default CardSlider;
