/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

// import Heatmap from '../charts/Heatmap';
import HeatmapCircular from '../../charts/HeatmapCircular';
import MultiLine from '../../charts/MultiLine';
import BarChartScroll from '../../charts/BarChartScroll';

import CardSlider from '../../components/CardSlider';
import { AuthenticationContext } from '../../shared/AuthenticationContext';

import '../../../assets/css/primary.theme.css';

// const GeneratedChart = () => {
//   const [hasError, setErrors] = useState(false);
//   const [checkIns, setCheckIns] = useState([]);
//   useEffect(() => {
//     const fetchData = async () => {
//       await fetch('/data/sample-data.json')
//         .then((res) => res.json())
//         .then(({ data }) => setCheckIns({ data }.data))
//         .catch((err) => {
//           setErrors(err);
//         });
//     };
//     fetchData();
//   }, []);

//   return (
//     <>
//       <Charts height={200} type="bar" data={checkIns} />
//       {/* <span>{JSON.stringify(checkIns)}</span> */}
//       <span>Has error: {JSON.stringify(hasError)}</span>
//     </>
//   );
// };

/*
const GET_PLINKS = gql`
  {
    plinks {
      creatorId
      scannedId
      type
    }
  }
`;*/
/*
const GET_PLINK = gql`
  {
    plink(plinkID: "5eb33905c046590024cc9fbc") {
      creatorId
      scannedId
      type
    }
  }
`;*/

// const CREATE_PLINK = gql`
//   mutation CreatePlink(
//     $creatorId: String!
//     $scannedId: String!
//     $type: String!
//   ) {
//     createPlink(type: $type) {
//       creatorId
//       scannedId
//       type
//     }
//   }
// `;

// // This is only for testing purposes
// const CreatePlinkTestButton = ({ children }) => {
//   const [createPlink, { data }] = useMutation(CREATE_PLINK);
//   return (
//     <button
//       onClick={(e) => {
//         e.preventDefault();
//         createPlink({
//           variables: {
//             creatorId: '1234',
//             scannedId: '5678',
//             type: 'DEVICE_TO_DEVICE',
//           },
//         })
//           .then((res) => {
//             console.log('Successfully created a plink: ', res);
//           })
//           .catch((e) => {
//             console.log('Failed to create a plink: ', e);
//           });

//         console.log('data: ' + data);
//       }}
//     >
//       {children}
//     </button>
//   );
// };

// const CREATE_PLINK = gql`
//   mutation CreatePlink(
//     $creatorId: String!
//     $scannedId: String!
//     $type: String!
//   ) {
//     createPlink(type: $type) {
//       creatorId
//       scannedId
//       type
//     }
//   }
// `;

// // This is only for testing purposes
// const CreatePlinkTestButton = ({ children }) => {
//   const [createPlink, { data }] = useMutation(CREATE_PLINK);
//   return (
//     <button
//       onClick={(e) => {
//         e.preventDefault();
//         createPlink({
//           variables: {
//             creatorId: '1234',
//             scannedId: '5678',
//             type: 'DEVICE_TO_DEVICE',
//           },
//         })
//           .then((res) => {
//             console.log('Successfully created a plink: ', res);
//           })
//           .catch((e) => {
//             console.log('Failed to create a plink: ', e);
//           });

//         console.log('data: ' + data);
//       }}
//     >
//       {children}
//     </button>
//   );
// };

const plink = () => {
  return axios({
    method: 'post',
    url: '/api/plinks',
    data: {
      creatorId: '11b05d4b606586244c3a729c',
      scannedId: '22b05d4b606586244c3a729c',
      type: 'DEVICE_TO_DEVICE',
    },
  })
    .then(() => {
      console.log('Plink Plink');
    })
    .catch((e) => {
      console.error('What the plink?');
    });
};

const BusinessDashboardTest = () => {
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
          <button
            onClick={() => {
              plink();
            }}
          >
            Create a plink
          </button>
          <button
            onClick={() => {
              auth.logout();
            }}
          >
            Logout
          </button>
          <section>
            <div className="container-sm">
              <div className="row">
                <div className="col-12 py-4 py-lg-8">
                  <h1 className="mb-4">
                    Welcome back,{' '}
                    {auth.isAuthenticated ? auth.user.name : 'anonymous'}!
                  </h1>
                  <div className="card card-border card-border-xl border-primary shadow-lg">
                    <div className="card-body">
                      <h3 className="card-title text-body">Card title</h3>
                      <p className="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Facilis non dolore est fuga nobis ipsum illum
                        eligendi nemo iure repellat, soluta, optio minus ut
                        reiciendis voluptates enim impedit veritatis officiis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6 py-4">
                  <h3 className="card-title text-body">
                    Overview of QR code scans
                  </h3>
                  <BarChartScroll />
                </div>
                <div className="col-12 col-lg-6 py-4">
                  <h3 className="card-title text-body">
                    QR code exposure rate
                  </h3>
                  <HeatmapCircular />
                </div>
              </div>
              <div className="row">
                <div className="col-12 py-6 py-lg-8">
                  <h4 className="card-title text-body">
                    Exposure mitigation tips
                  </h4>
                  <CardSlider />
                </div>
              </div>
              <div className="row">
                <div className="col-12 py-4">
                  <h3 className="card-title text-body">
                    Overview of QR code scans
                  </h3>
                  <MultiLine />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default withRouter(BusinessDashboardTest);
