/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

// import Profiles from './Profiles';
// import DrawerLeft from '../components/DrawerLeft';

import '../../assets/css/primary.theme.css';

const BottomSheet = ({ isOpen, bottomSheet }) => {
  return (
    <SwipeableBottomSheet
      open={isOpen}
      onChange={bottomSheet.bind(this)}
      marginTop={20}
      style={{ zIndex: '10' }}
      bodyStyle={{
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem',
      }}
      fullScreen
    >
      <div className="PrimaryTheme">
        <div className="container-sm">
          <div className="row">
            <div className="col-12 pt-8 py-lg-8">
              <h3 className="mb-4">Recommendations</h3>
              <p className="mb-6  text-muted">
                Touchpoints such as the front door, cashier, loading dock are
                great places for QRs. You can add a nickname such as "Cash
                Register 1"
              </p>
              <h3 className="mb-4">How is exposure calculated?</h3>
              <p className="mb-6  text-muted">
                Touchpoints such as the front door, cashier, loading dock are
                great places for QRs. You can add a nickname such as "Cash
                Register 1"
              </p>
              <button
                className="btn btn-primary btn-block my-4"
                onClick={() => bottomSheet.close()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </SwipeableBottomSheet>
  );
};
const useBottomSheet = () => {
  const [isOpen, setBottomSheet] = useState(false);
  return {
    isOpen,
    bind: () => setBottomSheet.bind(this),
    close: () => setBottomSheet(false),
    open: () => setBottomSheet(true),
  };
};

const DetachedHook = () => {
  //const [open, openBottomSheet] = useState(false);
  const bottomSheet = useBottomSheet();
  return (
    <>
      <BottomSheet isOpen={bottomSheet.isOpen} bottomSheet={bottomSheet} />
      <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
        <section>
          <div className="container-sm">
            <button
              className="btn btn-primary btn-block my-4"
              onClick={() => bottomSheet.open()}
            >
              Close
            </button>
          </div>
        </section>
      </div>
    </>
  );
};

// const AttachedHook = () => {
//   const [open, openDrawer] = useState(false);
//   const onClickDrawer = () => {
//     openDrawer(!open);
//   };
//   return (
//     <>
//       <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
//         <DrawerLeft
//           open={open}
//           onClose={onClickDrawer}
//           placement="left"
//           width="90vw"
//           handler={false}
//         >
//           <Profiles />
//         </DrawerLeft>
//       </div>
//     </>
//   );
// };

//console.log(AttachedHook);
export default withRouter(DetachedHook);
