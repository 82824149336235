/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
//import axios from 'axios';
import {
  CHART_TIMESTEP,
  buildQueryForChart,
  QUERY_TYPE,
  mapQueryDataForChart,
} from '../../../charts/ChartQueryUtil';

import { useQuery } from '@apollo/react-hooks';
//import { Query } from '@apollo/react-components';

import { DashboardContext } from '../../../shared/DashboardContext';
import { AuthenticationContext } from '../../../shared/AuthenticationContext';

import Charts from '../../../charts/mobile/Charts';

import Heatmap from '../../../charts/Heatmap';
// import HeatmapCircular from '../charts/HeatmapCircular';
// import MultiLine from '../charts/MultiLine';
import BarChart from '../../../charts/BarChart';
import Sparkline from '../../../charts/Sparkline';
import SparklineExposure from '../../../charts/SparklineExposure';

import CardSlider from '../../../components/CardSlider';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

import '../../../../assets/css/primary.theme.css';

const GeneratedChart = ({ siteId, timeStep }) => {
  // const [hasError, setErrors] = useState(false);
  // const [checkIns, setCheckIns] = useState([]);
  const query = buildQueryForChart(
    QUERY_TYPE.SITE_CHECKINS,
    timeStep,
    '5ec968b0b082c03754593734',
  );

  const { loading, error, data } = useQuery(query, { variables: {} });
  if (loading) return <p>Good things take time....</p>;
  if (error) return <p>Something went wrong...</p>;
  console.log(data);
  let mappedData = mapQueryDataForChart(data, timeStep);
  console.log(mappedData);

  return (
    <div>
      <Charts height={200} type="bar" data={mappedData} />
    </div>
  );
};

const SiteAdminDashboard = () => {
  const [open, openBottomSheet] = useState(false);
  const [plinkChartInterval, setPlinkChartInterval] = useState(
    CHART_TIMESTEP.THIS_WEEK,
  );
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <DashboardContext.Consumer>
          {({ organizationId, siteId, setOrganization, setSite }) => (
            <>
              <div>Organization: {organizationId}</div>
              <div>Site: {siteId}</div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOrganization('123');
                }}
              >
                Set organizationId
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setSite('12345');
                }}
              >
                Set organizationId
              </button>

              <SwipeableBottomSheet
                open={open}
                onChange={openBottomSheet.bind(this)}
                marginTop={20}
                style={{ zIndex: '10' }}
                bodyStyle={{
                  borderTopLeftRadius: '1rem',
                  borderTopRightRadius: '1rem',
                }}
                fullScreen
              >
                <div className="PrimaryTheme">
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-8 py-lg-8">
                        <h3 className="mb-4">Recommendations</h3>
                        <p className="mb-6  text-muted">
                          Touchpoints such as the front door, cashier, loading
                          dock are great places for QRs. You can add a nickname
                          such as "Cash Register 1"
                        </p>
                        <h3 className="mb-4">How is exposure calculated?</h3>
                        <p className="mb-6  text-muted">
                          Touchpoints such as the front door, cashier, loading
                          dock are great places for QRs. You can add a nickname
                          such as "Cash Register 1"
                        </p>
                        <button
                          className="btn btn-primary btn-block my-4"
                          onClick={() => openBottomSheet(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </SwipeableBottomSheet>
              <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
                <GeneratedChart siteId={siteId} timeStep={plinkChartInterval} />
                <section>
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-4 py-lg-8">
                        <div className="row">
                          <div className="col">
                            <h2 className="mb-4">
                              <Link
                                to="/dashboard/business/codes"
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit',
                                }}
                              >
                                <i className="fe fe-arrow-left mr-2" />
                              </Link>
                              Welcome Back!
                            </h2>
                          </div>
                          <div className="col-auto">
                            <span className="badge badge-pill badge-primary-soft">
                              <span className="h6 text-uppercase font-weight-bold">
                                {auth.isAuthenticated
                                  ? auth.user.name
                                  : 'anonymous'}
                                !{/* Admin view */}
                              </span>
                            </span>
                          </div>
                        </div>
                        <h4 className="text-muted">
                          311 Winthrop Drive, Cambridge MA 02138
                        </h4>
                      </div>
                      <div className="col-6 col-lg-3 py-3 pl-3 pr-0">
                        <div className="card shadow-dark">
                          <Link
                            className="card-body p-5 lift"
                            style={{ minHeight: '13rem' }}
                            to="/dashboard/business/codes"
                          >
                            <h1 className="font-weight-bold">
                              50
                              <i className="fe fe-arrow-up ml-2" />
                            </h1>
                            <p className="card-text-sm card-text mb-0">
                              Today's plinks formed at your site
                            </p>
                            <Sparkline />
                            <button
                              className="btn btn-primary btn-block btn-sm"
                              style={{ fontSize: '0.8rem' }}
                            >
                              View QR codes
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="col-6 col-lg-3 p-3">
                        <div className="card shadow-dark">
                          <Link
                            className="card-body p-5 lift"
                            style={{ minHeight: '13rem' }}
                            to="/dashboard/business/some-site/members"
                          >
                            <h1 className="font-weight-bold text-success">
                              3/10
                            </h1>
                            <p className="card-text-sm card-text mb-0">
                              Aggregated Member Exposure Score
                            </p>
                            <SparklineExposure />
                            <button
                              className="btn btn-primary btn-block btn-sm"
                              style={{ fontSize: '0.8rem' }}
                            >
                              View members
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 py-4">
                        <h3 className="card-title text-body">
                          Overview of QR code scans
                        </h3>
                        <div className="form-selectgroup">
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="Today"
                              className="form-selectgroup-input"
                              onChange={() =>
                                setPlinkChartInterval(CHART_TIMESTEP.TODAY)
                              }
                            />
                            <span className="form-selectgroup-label">
                              Today
                            </span>
                          </label>
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="thisWeek"
                              className="form-selectgroup-input"
                              onChange={() =>
                                setPlinkChartInterval(CHART_TIMESTEP.THIS_WEEK)
                              }
                              defaultChecked
                            />
                            <span className="form-selectgroup-label">
                              This Week
                            </span>
                          </label>
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="thisMonth"
                              className="form-selectgroup-input"
                              onChange={() =>
                                setPlinkChartInterval(CHART_TIMESTEP.THIS_MONTH)
                              }
                            />
                            <span className="form-selectgroup-label">
                              This Month
                            </span>
                          </label>
                        </div>
                        <BarChart />
                      </div>
                      <div className="col-12 col-lg-6 pt-4 pb-0">
                        <div className="row">
                          <div className="col">
                            <h3 className="card-title text-body mb-0">
                              QR code exposure levels
                            </h3>
                          </div>
                          <div className="col-auto">
                            <i
                              className="fe fe-info"
                              onClick={() => openBottomSheet(true)}
                            />
                          </div>
                        </div>
                        <Heatmap />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 py-6 py-lg-8">
                        <div className="row">
                          <div className="col">
                            <h3 className="card-title text-body">
                              Recommendations
                            </h3>
                          </div>
                          <div className="col-auto">
                            <i
                              className="fe fe-info"
                              onClick={() => openBottomSheet(true)}
                            />
                          </div>
                        </div>
                        <CardSlider />
                      </div>
                    </div>
                    {/* <div className="row">
              <div className="col-12 py-4">
                <h3 className="card-title text-body">
                  Overview of QR code scans
                </h3>
                <MultiLine />
                <button
                  className="btn btn-primary btn-block my-4"
                  onClick={() => {
                    plink();
                  }}
                >
                  Test a plink now <i className="fe fe-arrow-right" />
                </button>
              </div>
            </div> */}
                  </div>
                </section>
              </div>
            </>
          )}
        </DashboardContext.Consumer>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default withRouter(SiteAdminDashboard);
