/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import BarChart from '../../../charts/BarChart';
import { DashboardContext } from '../../../shared/DashboardContext';
import { AuthenticationContext } from '../../../shared/AuthenticationContext';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import Select from 'react-select';

import '../../../../assets/css/primary.theme.css';

const options = [
  { value: 'grocery', label: 'Scanned' },
  { value: 'restaurant', label: 'Deactivated' },
  { value: 'pizza-shop', label: 'Relocated' },
];

const options2 = [
  { value: 'grocery', label: 'Member' },
  { value: 'restaurant', label: 'Non-Member' },
];

const options3 = [
  { value: 'grocery', label: 'Display' },
  { value: 'restaurant', label: 'Notify' },
];

class TriggerSelector extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log('Option selected:', this.state.selectedOption),
    );
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
        placeholder="Choose a Trigger"
      />
    );
  }
}

class MemberSelector extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log('Option selected:', this.state.selectedOption),
    );
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options2}
        placeholder="Choose a Type"
      />
    );
  }
}

class ActionSelector extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log('Option selected:', this.state.selectedOption),
    );
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options3}
        placeholder="Choose an Action"
      />
    );
  }
}

const SiteAdminScannable = () => {
  const [open, openBottomSheet] = useState(false);
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <DashboardContext.Consumer>
          {({ organizationId, siteId, setOrganization, setSite }) => (
            <>
              <SwipeableBottomSheet
                open={open}
                onChange={openBottomSheet.bind(this)}
                marginTop="20"
                style={{ zIndex: '10' }}
                bodyStyle={{
                  borderTopLeftRadius: '1rem',
                  borderTopRightRadius: '1rem',
                }}
                fullScreen
              >
                <div className="PrimaryTheme">
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-8 py-lg-8">
                        <h3 className="mb-4">Create a new workflow</h3>
                        <p className="mb-6 text-muted">
                          A workflow is triggered by an event. For example, an
                          event could be a scan. When a member scans this QR it
                          could perform an action such as display a message.
                        </p>
                        <h3 className="mb-3">When this QR is</h3>
                        <TriggerSelector />
                        <h3 className="my-3">by (optional)</h3>
                        <MemberSelector />
                        <h3 className="my-3">then</h3>
                        <ActionSelector />
                        <textarea
                          className="form-control my-3"
                          data-toggle="autosize"
                          placeholder="Message"
                          style={{
                            overflow: 'hidden',
                            overflowWrap: 'break-word',
                            resize: 'none',
                            height: '56px',
                          }}
                          defaultValue={''}
                        />

                        <button
                          className="btn btn-block btn-primary mt-6 lift"
                          onClick={() => openBottomSheet(false)}
                        >
                          Create Workflow
                        </button>
                        <div className="text-center">
                          <button
                            className="btn"
                            onClick={() => openBottomSheet(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwipeableBottomSheet>
              <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
                <section>
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-4 py-lg-8">
                        <div className="row">
                          <div className="col">
                            <h2 className="mb-4">
                              <Link
                                to="/dashboard/business/codes"
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit',
                                }}
                              >
                                <i className="fe fe-arrow-left mr-2" />
                              </Link>
                              Cash Register 1
                            </h2>
                          </div>
                          <div className="col-auto">
                            <div className="small mt-1">
                              <span className="text-success">● Active</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 p-3">
                        <div className="form-selectgroup">
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="home"
                              className="form-selectgroup-input"
                              defaultChecked
                            />
                            <span className="form-selectgroup-label">
                              Weekly
                            </span>
                          </label>
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="user"
                              className="form-selectgroup-input"
                            />
                            <span className="form-selectgroup-label">
                              Monthly
                            </span>
                          </label>
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="circle"
                              className="form-selectgroup-input"
                              defaultChecked
                            />
                            <span className="form-selectgroup-label">
                              Check-ins
                            </span>
                          </label>
                          <label className="form-selectgroup-item">
                            <input
                              type="radio"
                              name="icons"
                              defaultValue="circle"
                              className="form-selectgroup-input"
                            />
                            <span className="form-selectgroup-label">
                              Exposure
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 p-3">
                        <BarChart />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 py-4">
                        <div className="row">
                          <div className="col">
                            <h3 className="card-title text-body">
                              QR Code Information
                            </h3>
                          </div>
                          <div className="col-auto">
                            <i
                              className="fe fe-info"
                              onClick={() => openBottomSheet(true)}
                            />
                          </div>
                        </div>
                        <div className="card shadow-dark">
                          <div className="card-body d-flex align-items-center">
                            <div className="row w-100 m-0">
                              <div className="col-auto p-0">
                                <div className="avatar avatar-xl">
                                  <img
                                    src="../../../../assets/img/avatars/qr-small.jpg"
                                    alt="..."
                                    className="avatar-img rounded"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-label-group">
                                  <input
                                    type="text"
                                    className="form-control form-control-flush"
                                    placeholder="e.g. Cash Register 1"
                                    value="Cash Register 1"
                                  />
                                  <label htmlFor="cardEmail">
                                    Edit nickname
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <h3 className="card-title text-body">Workflows</h3>
                      </div>
                      <div className="col-auto">
                        <i className="fe fe-info" />
                      </div>
                    </div>
                    <div className="card shadow-dark">
                      <div className="card-body py-4 px-6">
                        <p className="mb-0">
                          When the QR of an&nbsp;
                          <span class="badge badge-pill badge-primary-soft">
                            an office space
                          </span>
                          &nbsp;is scanned by&nbsp;
                          <span class="badge badge-pill badge-primary-soft">
                            anyone
                          </span>
                          ,&nbsp;
                          <span class="badge badge-pill badge-primary-soft">
                            display
                          </span>
                          &nbsp;details on social distancing
                        </p>
                      </div>
                      <a className="card-meta" href="#!">
                        <hr className="card-meta-divider" />
                        <div className="avatar avatar-sm mr-2">
                          <img
                            src="../../../../assets/img/avatars/ministry.jpg"
                            alt="..."
                            className="avatar-img rounded"
                          />
                        </div>
                        <h6 className="text-uppercase text-muted mr-2 mb-0">
                          Ministry of Health
                        </h6>
                        <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                          <time dateTime="2019-05-02">05.02 - 05.14</time>
                        </p>
                      </a>
                    </div>
                    <div
                      className="card mt-4"
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px #c1c1c1 dashed',
                      }}
                    >
                      <div className="card-body text-center">
                        <button
                          className="btn btn-primary my-4"
                          onClick={() => {
                            openBottomSheet(true);
                          }}
                        >
                          <i className="fe fe-plus mr-2" />
                          New workflow
                        </button>
                      </div>
                    </div>
                    <button
                      className="btn btn-outline-danger btn-block my-4"
                      // modal: are you sure you want to deactivate this code?
                      onClick={() => {
                        plink();
                      }}
                    >
                      Deactivate Code
                    </button>
                  </div>
                </section>
              </div>
            </>
          )}
        </DashboardContext.Consumer>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default withRouter(SiteAdminScannable);
