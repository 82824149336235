/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useForm, useStep } from 'react-hooks-helper';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

import { DashboardContext } from '../shared/DashboardContext';
import { AuthenticationContext } from '../shared/AuthenticationContext';

import Select from 'react-select';
import '../../assets/css/primary.theme.css';

const options = [
  { value: 'grocery', label: 'Grocery Store' },
  { value: 'restaurant', label: 'Restaurant' },
  { value: 'pizza-shop', label: 'Pizza Shop' },
  { value: 'bakery', label: 'Bakery' },
  { value: 'school', label: 'School' },
  { value: 'office', label: 'Office' },
  { value: 'warehouse', label: 'Warehouse' },
  { value: 'supermarket', label: 'Supermarket' },
  { value: 'hotel', label: 'Hotel' },
  { value: 'gym', label: 'Gym' },
  { value: 'stand', label: 'Stand' },
  { value: 'religious-site', label: 'Religious Site' },
  { value: 'church', label: 'Church' },
  { value: 'general-store', label: 'General Store' },
  { value: 'gas-station', label: 'Gas Station' },
  { value: 'doctror-office', label: 'Doctors Office' },
  { value: 'hospital', label: 'Hospital' },
  { value: 'pharmacy', label: 'Pharmacy' },
  { value: 'newspaper-stand', label: 'Newspaper Stand' },
  { value: 'kiosk', label: 'Kiosk' },
  { value: 'cafe', label: 'Cafe' },
];

class Selector extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log('Option selected:', this.state.selectedOption),
    );
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
        placeholder="Select Site Type..."
      />
    );
  }
}

const Start = ({ setForm, formData, navigation }) => {
  const { organizationName, email, password } = formData;
  const { next } = navigation;

  const history = useHistory();
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <div className="PrimaryTheme">
          <section className="section-border border-primary">
            <div className="container-sm d-flex flex-column">
              <div className="row align-items-center justify-content-center no-gutters min-vh-100">
                <div className="col-12 col-md-8 col-lg-6 py-8 py-md-11">
                  <div className="row">
                    <div className="col-12">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">Start</li>
                        <li className="breadcrumb-item text-muted">Setup</li>
                        <li className="breadcrumb-item text-muted">Generate</li>
                      </ol>
                    </div>
                  </div>

                  <h2 className="mb-6 font-weight-bold">Let's get started</h2>
                  <div className="mb-6">
                    <div className="form-label-group">
                      <input
                        type="text"
                        className="form-control form-control-flush"
                        placeholder="Organization Name"
                        name="organizationName"
                        value={organizationName}
                        onChange={setForm}
                      />
                      <label>Organization Name</label>
                    </div>
                    {auth.isAuthenticated ? null : (
                      <>
                        <div className="form-label-group">
                          <input
                            type="text"
                            className="form-control form-control-flush"
                            placeholder="Enter your Email"
                            name="email"
                            value={email}
                            onChange={setForm}
                          />
                          <label>Email</label>
                        </div>
                        <div className="form-label-group">
                          <input
                            type="password"
                            className="form-control form-control-flush"
                            id="cardPassword"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={setForm}
                          />
                          <label htmlFor="cardPassword">Password</label>
                        </div>
                      </>
                    )}
                    <div className="row mt-6">
                      <div className="col">
                        <button
                          className="btn lift"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          <i className="fe fe-arrow-left mr-2" />
                          Back
                        </button>
                      </div>
                      <div className="col-auto">
                        <button
                          className="btn btn-block btn-primary lift"
                          onClick={next}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </AuthenticationContext.Consumer>
  );
};

const Setup = ({ setForm, formData, navigation }) => {
  const { siteName, siteAddress, siteType } = formData;
  const { previous, next } = navigation;
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-8 col-lg-6 py-8 py-md-11">
              <div className="row">
                <div className="col-12">
                  <ol className="breadcrumb">
                    <li
                      className="breadcrumb-item text-muted"
                      onClick={previous}
                    >
                      Start
                    </li>
                    <li className="breadcrumb-item">Setup</li>
                    <li className="breadcrumb-item text-muted">Generate</li>
                  </ol>
                </div>
              </div>
              <h2 className="mb-6 font-weight-bold">
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                  onClick={previous}
                >
                  <i className="fe fe-arrow-left mr-2" />
                </a>
                Setup your first site
              </h2>
              <p className="mb-6  text-muted">
                Where is your site located?
                <br />
                You can add additional sites in your dashboard
              </p>
              <div className="mb-4">
                <div className="form-label-group mb-6">
                  <input
                    type="text"
                    className="form-control form-control-flush"
                    placeholder="Site Name"
                    name="siteName"
                    value={siteName}
                    onChange={setForm}
                  />
                  <label>Site Name</label>
                </div>
                <div className="form-label-group mb-6">
                  <input
                    type="text"
                    className="form-control form-control-flush"
                    placeholder="Site Address"
                    name="siteAddress"
                    value={siteAddress}
                    onChange={setForm}
                  />
                  <label>Site Address</label>
                </div>
                <Selector name="siteType" value={siteType} onChange={setForm} />
                <button
                  className="btn btn-block btn-primary mt-6 lift"
                  onClick={next}
                >
                  Continue
                </button>
              </div>
              <p className="mb-0 font-size-sm text-center text-muted">
                <button className="btn" onClick={next}>
                  Skip
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Generate = ({ setForm, formData, navigation }) => {
  const { pointLabel } = formData;
  const { previous, next } = navigation;
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-8 col-lg-6 py-8 py-md-11">
              <div className="row">
                <div className="col-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item text-muted">Start</li>
                    <li className="breadcrumb-item text-muted">Setup</li>
                    <li className="breadcrumb-item">Generate</li>
                  </ol>
                </div>
              </div>
              <h2 className="mb-6 font-weight-bold">
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                  onClick={previous}
                >
                  <i className="fe fe-arrow-left mr-2" />
                </a>
                Add a few points to your site
              </h2>
              <p className="mb-6  text-muted">
                Touchpoints such as the front door, cashier, loading dock are
                great places for check-ins or QRs. You can add a nickname such
                as "Cash Register 1". You can add additional touchpoints in your
                dashboard.
              </p>
              <div className="card shadow-dark mb-4">
                <div className="card-body d-flex align-items-center">
                  <div className="row w-100 m-0">
                    <div className="col-auto p-0">
                      <div className="avatar avatar-xl">
                        <img
                          src="../../assets/img/avatars/qr-small.jpg"
                          alt="..."
                          className="avatar-img rounded"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-label-group">
                        <input
                          type="text"
                          className="form-control form-control-flush"
                          placeholder="e.g. Cash Register 1"
                          name="pointLabel"
                          value={pointLabel}
                          onChange={setForm}
                        />
                        <label>Add a nickname</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card"
                style={{
                  backgroundColor: 'transparent',
                  border: '1px #c1c1c1 dashed',
                }}
              >
                <div className="card-body text-center">
                  <button
                    className="btn btn-primary my-4"
                    onClick={() => {
                      console.log('hello');
                    }}
                  >
                    <i className="fe fe-plus mr-2" />
                    New touchpoint
                  </button>
                </div>
              </div>
              <button
                className="btn btn-block btn-primary mt-6 lift"
                onClick={next}
              >
                Continue
              </button>
              <p className="mb-0 font-size-sm text-center text-muted">
                <button className="btn" onClick={next}>
                  Skip
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Print = ({ setForm, formData, navigation }) => {
  const { printingFormat } = formData;
  const { previous, next } = navigation;
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-8 col-lg-6 py-8 py-md-11">
              <div className="row">
                <div className="col-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item text-muted">Start</li>
                    <li className="breadcrumb-item text-muted">Setup</li>
                    <li className="breadcrumb-item">Generate</li>
                  </ol>
                </div>
              </div>
              <h2 className="mb-6 font-weight-bold">
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                  onClick={previous}
                >
                  <i className="fe fe-arrow-left mr-2" />
                </a>
                Print your QR Codes
              </h2>
              <p className="mb-6  text-muted">
                Select your printing format!
                <br />
                Smaller sizes look better, but you'll need a pair of scissors
                handy. There are additional formats available in your dashboard.
              </p>
              <div className="mb-4">
                <div className="form-selectgroup">
                  <label className="form-selectgroup-item">
                    <input
                      type="radio"
                      name="printingFormat"
                      className="form-selectgroup-input"
                      defaultValue="1"
                      checked={printingFormat === '1'}
                      onChange={setForm}
                    />
                    <span className="form-selectgroup-label">
                      1 QR per page
                    </span>
                  </label>
                  <label className="form-selectgroup-item">
                    <input
                      type="radio"
                      name="printingFormat"
                      className="form-selectgroup-input"
                      defaultValue="4"
                      checked={printingFormat === '4'}
                      onChange={setForm}
                    />
                    <span className="form-selectgroup-label">
                      4 QR per page
                    </span>
                  </label>
                  <label className="form-selectgroup-item">
                    <input
                      type="radio"
                      name="printingFormat"
                      className="form-selectgroup-input"
                      defaultValue="9"
                      checked={printingFormat === '9'}
                      onChange={setForm}
                    />
                    <span className="form-selectgroup-label">
                      9 QR per page
                    </span>
                  </label>
                </div>
              </div>

              <button
                className="btn btn-block btn-primary mt-6 lift"
                onClick={next}
              >
                Continue
              </button>
              <p className="mb-0 py-4 font-size-sm text-center text-muted">
                <button className="btn" onClick={next}>
                  I'll do this later
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Send = ({ setForm, formData, navigation }) => {
  const { sendToEmail } = formData;
  const { previous, go } = navigation;
  //const history = useHistory();
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-8 col-lg-6 py-8 py-md-11">
              <div className="row">
                <div className="col-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item text-muted">Start</li>
                    <li className="breadcrumb-item text-muted">Setup</li>
                    <li className="breadcrumb-item">Generate</li>
                  </ol>
                </div>
              </div>
              <h2 className="mb-6 font-weight-bold">
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                  onClick={previous}
                >
                  <i className="fe fe-arrow-left mr-2" />
                </a>
                We're all set!
              </h2>
              <p className="mb-6  text-muted">Send or download your QR codes</p>
              <div className="mb-4">
                <div className="form-label-group mb-6">
                  <input
                    type="text"
                    className="form-control form-control-flush"
                    placeholder="Send to email"
                    name="sendToEmail"
                    value={sendToEmail}
                    onChange={setForm}
                  />
                  <label>Send to email</label>
                </div>
                <Link to="/dashboard">Download PDF</Link>
                <button
                  className="btn btn-block btn-primary mt-6 lift"
                  onClick={() => go('confirmation')}
                >
                  Continue to Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($organization: OrganizationInput!) {
    createOrganization(organization: $organization) {
      organization {
        organizationId
      }
      message
      success
    }
  }
`;

// create organization -> orgId
// create site with orgId -> siteId
// (optional) create point with siteId -> pointId
// if no auth:
// create user, add to user.roles with orgId and siteId -> user Id
// update user.roles with orgId and siteId, ORG_ADMIN -> userId

const SetupOrganizationButton = ({ formData, children }) => {
  const [createOrganization, { data }] = useMutation(CREATE_ORGANIZATION);
  return (
    <AuthenticationContext.Consumer>
      {({ auth }) => (
        <DashboardContext.Consumer>
          {({ setOrganization }) => (
            <button
              className="btn btn-block btn-primary mt-6 lift"
              onClick={(e) => {
                e.preventDefault();
                createOrganization({
                  variables: {
                    organization: {
                      name: formData.organizationName,
                      contact: {
                        email: 'test@email.com',
                      },
                    },
                  },
                })
                  .then((res) => {
                    console.log('Successfully created an organization: ', res);
                    console.log(auth._id);
                    console.log(auth);
                    setOrganization(
                      res.data.createOrganization.organization.organizationId,
                    );
                  })
                  .catch((e) => {
                    console.log('Failed to create an organization: ', e);
                  });
                console.log('data: ' + data);
              }}
            >
              {children}
            </button>
          )}
        </DashboardContext.Consumer>
      )}
    </AuthenticationContext.Consumer>
  );
};

const Confirmation = ({ formData }) => {
  return (
    <>
      <pre className="pre w3-container w3-light-grey">
        {JSON.stringify(formData, null, 2)}
      </pre>
      <SetupOrganizationButton formData={formData}>
        Submit
      </SetupOrganizationButton>
    </>
  );
};

const steps = [
  { id: 'start' },
  { id: 'setup' },
  { id: 'generate' },
  { id: 'print' },
  { id: 'send' },
  { id: 'confirmation' },
];

const defaultData = {
  organizationName: '',
  email: '',
  password: '',
  siteName: '',
  siteAddress: '',
  siteType: '',
  pointLabel: '',
  printingFormat: '',
  sendToEmail: '',
};

const ExampleRegistration = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = { formData, setForm, navigation };
  switch (id) {
    case 'start':
      return <Start {...props} />;
    case 'setup':
      return <Setup {...props} />;
    case 'generate':
      return <Generate {...props} />;
    case 'print':
      return <Print {...props} />;
    case 'send':
      return <Send {...props} />;
    case 'confirmation':
      return <Confirmation {...props} />;
    default:
      return null;
  }
};

export default ExampleRegistration;
