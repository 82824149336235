// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Howl } from 'howler';
import QrReader from 'react-qr-reader';
import '../../../assets/components/scanner.css';

const plink = () => {
  return axios({
    method: 'post',
    url: '/api/plinks',
    data: {
      creatorId: '11b05d4b606586244c3a729c',
      scannedId: '22b05d4b606586244c3a729c',
      type: 'DEVICE_TO_DEVICE',
    },
  })
    .then(() => {
      console.log('Plink Plink');
    })
    .catch(() => {
      console.error('What the plink?');
    });
};

class Scanner extends React.Component {
  componentDidMount() {
    window.sound = new Howl({ src: ['/assets/audio/beep.mp3'] });
  }
  constructor(props) {
    super(props);
    this.state = {
      delay: 500,
      result: 'No result',
      legacyMode: false,
    };

    this.handleScan = this.handleScan.bind(this);
    this.openImageDialog = this.openImageDialog.bind(this);
  }
  handleScan(result) {
    if (
      result &&
      result === 'https://www.plinks.io/s/5553d936-11ec-4e26-8d7a-452df697474d'
    ) {
      this.setState({ result });
      plink();
      window.sound.play();
      this.props.history.push('/s/5553d936-11ec-4e26-8d7a-452df697474d');
    }
  }
  handleError(err) {
    alert(
      'This app does not have permission to access your camera. Instead, you may take a picture of the QR code.',
    );
    this.setState({ legacyMode: true });
    console.error(err);
  }
  openImageDialog() {
    this.refs.scanner.openImageDialog();
  }
  render() {
    const previewStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    };
    const wrapperStyle = {
      position: 'relative',
      height: '100vh',
      overflow: 'hidden',
    };
    const featuredStyle = {
      position: 'absolute',
      width: 'calc(100vh)' /*'calc(100vh * (1000 / 562))',*/,
      minWwidth: '100%',
      minHheight: '100%',
      // top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
    const { legacyMode } = this.state;
    return (
      <div style={wrapperStyle}>
        <div style={featuredStyle}>
          <QrReader
            ref="scanner"
            delay={this.state.delay}
            style={previewStyle}
            onError={this.handleError}
            onScan={this.handleScan}
            showViewFinder={false}
            facingMode="environment"
            legacyMode={legacyMode}
          />
          {legacyMode && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              className="btn btn-white mt-6 md-5"
              onClick={this.openImageDialog.bind(this)}
            >
              Take a picture
            </a>
          )}
        </div>
        <div className="periscope"></div>
        <div className="ocrloader" style={{ position: 'absolute' }}>
          <em></em>
          <div></div>
          <span></span>
        </div>
      </div>
    );
  }
}

export default withRouter(Scanner);
