// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React, { useState } from 'react';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';

import { AuthenticationContext } from '../../shared/AuthenticationContext';
import '../../../assets/css/primary.theme.css';

const Signin = () => {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const onSubmit = (e, auth) => {
    e.preventDefault();

    axios({
      method: 'post',
      url: '/api/auth/login',
      data: {
        email,
        password,
      },
    })
      .then((res) => {
        const { tokens, user } = res.data;
        const { access } = tokens;
        const authToken = access && access.token;
        auth.login(authToken, user);
        console.log(user);
        history.push('/profiles');
      })
      .catch((e) => {
        console.error('Login failed: ', e);
      });
  };

  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <div className="PrimaryTheme">
          <section className="section-border border-primary">
            <div className="container-sm d-flex flex-column">
              <div className="row align-items-center justify-content-center no-gutters min-vh-100">
                <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">
                  <h1 className="mb-0 font-weight-bold text-center">
                    {' '}
                    Sign In{' '}
                  </h1>
                  <p className="mb-6 text-center text-muted"> Welcome back!</p>
                  <form className="mb-6" onSubmit={(e) => onSubmit(e, auth)}>
                    <div className="form-label-group">
                      <input
                        type="email"
                        className="form-control form-control-flush"
                        id="cardEmail"
                        placeholder="Email"
                        onChange={(e) => {
                          setEmail(e.target && e.target.value);
                        }}
                      />
                      <label htmlFor="cardEmail">Email</label>
                    </div>
                    <div className="form-label-group">
                      <input
                        type="password"
                        className="form-control form-control-flush"
                        id="cardPassword"
                        placeholder="Password"
                        onChange={(e) => {
                          setPassword(e.target && e.target.value);
                        }}
                      />
                      <label htmlFor="cardPassword">Password</label>
                    </div>
                    <div className="mt-6">
                      <button
                        className="btn btn-block btn-primary lift"
                        type="submit"
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                  <p className="mb-0 font-size-sm text-center text-muted">
                    Don't have an account yet?
                    <Link to="/signup"> Sign up</Link>.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default Signin;
