import React, { useRef, useEffect } from 'react';
import { Heatmap } from '@antv/g2plot';

export const HeatmapCircular = () => {
  const data = [
    { day: 'Mon', value: 70, scannable: 'Cashier' },
    { day: 'Tue', value: 70, scannable: 'Cashier' },
    { day: 'Wed', value: 70, scannable: 'Cashier' },
    { day: 'Thu', value: 70, scannable: 'Cashier' },
    { day: 'Fri', value: 70, scannable: 'Cashier' },
    { day: 'Sat', value: 70, scannable: 'Cashier' },
    { day: 'Sun', value: 70, scannable: 'Cashier' },
    { day: 'Mon', value: 70, scannable: 'Front Door' },
    { day: 'Tue', value: 70, scannable: 'Front Door' },
    { day: 'Wed', value: 70, scannable: 'Front Door' },
    { day: 'Thu', value: 70, scannable: 'Front Door' },
    { day: 'Fri', value: 70, scannable: 'Front Door' },
    { day: 'Sat', value: 70, scannable: 'Front Door' },
    { day: 'Sun', value: 70, scannable: 'Front Door' },
    { day: 'Mon', value: 70, scannable: 'Back Door' },
    { day: 'Tue', value: 70, scannable: 'Back Door' },
    { day: 'Wed', value: 70, scannable: 'Back Door' },
    { day: 'Thu', value: 70, scannable: 'Back Door' },
    { day: 'Fri', value: 70, scannable: 'Back Door' },
    { day: 'Sat', value: 70, scannable: 'Back Door' },
    { day: 'Sun', value: 70, scannable: 'Back Door' },
    { day: 'Mon', value: 70, scannable: 'Loading Dock' },
    { day: 'Tue', value: 70, scannable: 'Loading Dock' },
    { day: 'Wed', value: 70, scannable: 'Loading Dock' },
    { day: 'Thu', value: 70, scannable: 'Loading Dock' },
    { day: 'Fri', value: 70, scannable: 'Loading Dock' },
    { day: 'Sat', value: 70, scannable: 'Loading Dock' },
    { day: 'Sun', value: 70, scannable: 'Loading Dock' },
  ];

  const container = useRef(null);
  useEffect(() => {
    if (!container.current) {
      return;
    }
    const config = {
      data,
      forceFit: true,
      xField: 'day',
      yField: 'scannable',
      colorField: 'value',
      shapeType: 'circle', //rect
      color: ['#0d5fbb', '#7eadfc', '#fd8b6f', '#aa3523'],
      legend: { visible: false }, //need this or else on resize there will be error for some reason
      xAxis: { grid: { visible: false } },
      yAxis: { grid: { visible: false } },
    };
    const heatmapPlot = new Heatmap(container.current, config);
    heatmapPlot.render();
  }, [data]);
  return <div ref={container}></div>;
};

export default HeatmapCircular;
