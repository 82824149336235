import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';

import faker from 'faker';

const createUser = () => ({
  id: faker.random.uuid(),
  name: faker.name.findName(),
  email: faker.internet.email(),
  address: faker.address.streetAddress(),
  bio: faker.lorem.sentence(),
  image: faker.image.avatar(),
});

const createUsers = (numUsers = 5) =>
  new Array(numUsers).fill(undefined).map(createUser);

const fakeUsers = createUsers(2000);

const TextField = styled.input`
  display: block;
  width: 100%;
  min-height: calc(1.4285714em + 0.875rem + 2px);
  padding: 0.4375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714;
  color: #354052;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(110, 117, 130, 0.2);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    color: inherit;
    background-color: #fff;
    border-color: #7aade9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
  }
`;

// const ClearButton = styled.button`
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
//   height: 34px;
//   width: 32px;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const customStyles = {
  headRow: {
    style: {
      border: 'none',
      padding: '0px',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '1.0625rem',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(234, 239, 253)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
};

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      value={filterText}
      onChange={onFilter}
      autoComplete="off"
    />
    <div className="form-selectgroup mt-4">
      <label className="form-selectgroup-item">
        <input
          type="checkbox"
          name="icons"
          defaultValue="daily"
          className="form-selectgroup-input"
        />
        <span className="form-selectgroup-label">Temperature Check</span>
      </label>
      <label className="form-selectgroup-item">
        <input
          type="checkbox"
          name="icons"
          defaultValue="weekly"
          className="form-selectgroup-input"
        />
        <span className="form-selectgroup-label">Symptom Check</span>
      </label>
    </div>
    {/* <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton> */}
  </>
);

// const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
//   <div className="round">
//     <input
//       type="checkbox"
//       className="custom-control-input"
//       ref={ref}
//       {...rest}
//     />
//     <label for="checkbox" className="custom-control-label" onClick={onClick} />
//   </div>
// ));

const BasicTable = () => {
  const history = useHistory();
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false,
  );
  const filteredItems = fakeUsers.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      style: {
        color: '#202124',
        fontSize: '1.0625rem',
        fontWeight: 500,
      },
    },
    //   {
    //     name: 'Email',
    //     selector: 'email',
    //     sortable: true,
    //     style: {
    //       color: 'rgba(0,0,0,.54)',
    //     },
    //   },
    {
      cell: () => (
        <i
          className="fe fe-arrow-right"
          onClick={() =>
            history.push('/dashboard/business/some-site/members/some-member')
          }
        />
      ),
      style: {
        color: '#202124',
        fontSize: '1.0625rem',
        fontWeight: 500,
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      pagination
      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={subHeaderComponentMemo}
      customStyles={customStyles}
      selectableRows
      // selectableRowsComponent={BootyCheckbox}
      persistTableHead
      highlightOnHover
      pointerOnHover
      noHeader
      // noDataComponent={<div style={{ padding: '24px' }}>Nao ha anexos...</div>}
    />
  );
};

export default BasicTable;
