import React from 'react';
import F2 from '@antv/f2';

class Heatmap extends React.Component {
  componentDidMount() {
    const data = [
      [0, 0, 10],
      [0, 1, 19],
      [0, 2, 8],
      [0, 3, 24],
      [0, 4, 67],
      [0, 5, 67],
      [0, 6, 67],
      [1, 0, 92],
      [1, 1, 58],
      [1, 2, 78],
      [1, 3, 117],
      [1, 4, 48],
      [1, 5, 67],
      [1, 6, 67],
      [2, 0, 35],
      [2, 1, 15],
      [2, 2, 123],
      [2, 3, 64],
      [2, 4, 52],
      [2, 5, 67],
      [2, 6, 67],
      [3, 0, 72],
      [3, 1, 132],
      [3, 2, 114],
      [3, 3, 19],
      [3, 4, 16],
      [3, 5, 67],
      [3, 6, 67],
    ];
    const source = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const obj = {};
      obj.name = item[0];
      obj.day = item[1];
      obj.sales = item[2];
      source.push(obj);
    }
    const chart = new F2.Chart({
      id: 'container-2',
      pixelRatio: window.devicePixelRatio,
    });
    this.chart = chart;

    chart.source(source, {
      name: {
        type: 'cat',
        values: [
          'Front Door',
          'Cash Register 1',
          'Cash Register 2',
          'Loading Dock',
        ],
      },
      day: {
        type: 'cat',
        values: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      },
    });
    chart
      .polygon()
      .position('day*name')
      .color('sales', '#bbcbfd-#6d8fff-#003cff')
      .style({
        lineWidth: 3,
        stroke: '#fff',
        lineJoin: 'round',
        lineCap: 'round',
      })
      .animate({
        appear: {
          animation: 'fadeIn',
          duration: 800,
        },
      });
    chart.render();
  }
  componentWillUnmount() {
    // Make sure to destroy Typed instance on unmounting
    // to prevent memory leaks
    this.chart.destroy();
  }
  render() {
    return <canvas id="container-2" width="375" height="200"></canvas>;
  }
}

export default Heatmap;
