/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';

import { DashboardContext } from '../shared/DashboardContext';
import { AuthenticationContext } from '../shared/AuthenticationContext';

import '../../assets/css/primary.theme.css';

const ProfileComponent = ({ profile }) => {
  const history = useHistory();
  // TODO refactor, since this is generated for every component rendered
  // issue can't use history.push hook if brought outside component.
  const updateDashboardContext = (setOrganization, setSite) => {
    setOrganization(profile.organization);
    setSite(profile.site);
    history.push('/dashboard');
  };
  console.log(profile);
  console.log(profile.role);
  return (
    <DashboardContext.Consumer>
      {({ setOrganization, setSite }) => (
        <div
          className="row py-2 px-4"
          style={{
            borderBottom: '2px solid white',
          }}
          onClick={(e) => {
            e.preventDefault();
            updateDashboardContext(setOrganization, setSite);
          }}
        >
          <div className="col mt-2">
            <div className="avatar avatar-lg mr-4">
              <span className="avatar-title bg-primary-soft rounded">
                <i className="fe fe-user" />
              </span>
            </div>
            {profile.role === 'SITE_MEMBER'
              ? 'Site Member'
              : profile.role === 'SITE_ADMIN'
              ? 'Site Administrator'
              : profile.role === 'ORG_ADMIN'
              ? 'Organization Administrator'
              : profile.role === 'PHO_ADMIN'
              ? 'Organization Administrator'
              : 'Unknown'}
          </div>
          <div className="col-auto p-0">
            <a href="/dashboard/business/" className="btn lift">
              <i className="fe fe-arrow-right" />
            </a>
          </div>
        </div>
      )}
    </DashboardContext.Consumer>
  );
};

const Profiles = () => {
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <>
          <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
            <div className="container-sm">
              <div className="row">
                <div className="col-12 pt-6 py-lg-8">
                  <h2 className="mt-8 font-weight-bolder">Your Profiles</h2>
                  <p className="mb-4 text-muted small">
                    Your device profile is automatically generated, it is never
                    shared or linked to any other organizational account you
                    create
                  </p>
                  <div
                    className="row py-2 px-4"
                    style={{
                      borderBottom: '2px solid white',
                    }}
                  >
                    <div className="col mt-2">
                      <div className="avatar avatar-lg mr-4">
                        <span className="avatar-title bg-primary rounded">
                          <i className="fe fe-lock" />
                        </span>
                      </div>
                      Device (Anonymous)
                    </div>
                    <div className="col-auto p-0">
                      <a href="/dashboard" className="btn lift">
                        <i className="fe fe-arrow-right" />
                      </a>
                    </div>
                  </div>
                  {auth.user && auth.user.roles
                    ? auth.user.roles.map((profile) => {
                        return (
                          <ProfileComponent
                            key={profile._id}
                            profile={profile}
                          />
                        );
                      })
                    : null}
                  <Link
                    className="btn btn-block btn-outline-primary mt-6 lift"
                    to="/create/1"
                  >
                    Create a new organization
                  </Link>
                  <p className="py-4 mb-0 font-size-sm text-primary text-center">
                    {auth.isAuthenticated ? (
                      <button
                        className="btn btn-block"
                        onClick={() => {
                          auth.logout();
                        }}
                      >
                        Log out
                      </button>
                    ) : (
                      <Link className="btn btn-block" to="/login">
                        Log in
                      </Link>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default withRouter(Profiles);
