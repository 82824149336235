// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import '../../assets/css/primary.theme.css';

const ResetPassword = () => {
  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">
              <h1 className="mb-0 font-weight-bold text-center">
                {' '}
                Password reset{' '}
              </h1>
              <p className="mb-6 text-center text-muted">
                {' '}
                Enter your email to reset your password.{' '}
              </p>
              <form className="mb-6">
                <div className="form-group">
                  {' '}
                  <label htmlFor="email"> Email Address </label>{' '}
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="name@address.com"
                  />{' '}
                </div>
                <button className="btn btn-block btn-primary" type="submit">
                  {' '}
                  Reset Password{' '}
                </button>
              </form>
              <p className="mb-0 font-size-sm text-center text-muted">
                {' '}
                Remember your password? <a href="/login">Log in</a>.{' '}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
