import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';

import BarChartExposure from '../../charts/BarChartExposure';

import TableSiteComponent from '../../components/TableSite';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

import '../../../assets/css/primary.theme.css';

const options = [
  { value: 'grocery', label: 'Grocery Store' },
  { value: 'restaurant', label: 'Restaurant' },
  { value: 'pizza-shop', label: 'Pizza Shop' },
  { value: 'bakery', label: 'Bakery' },
  { value: 'school', label: 'School' },
  { value: 'office', label: 'Office' },
  { value: 'warehouse', label: 'Warehouse' },
  { value: 'supermarket', label: 'Supermarket' },
  { value: 'hotel', label: 'Hotel' },
  { value: 'gym', label: 'Gym' },
  { value: 'stand', label: 'Stand' },
  { value: 'religious-site', label: 'Religious Site' },
  { value: 'church', label: 'Church' },
  { value: 'general-store', label: 'General Store' },
  { value: 'gas-station', label: 'Gas Station' },
  { value: 'doctror-office', label: 'Doctors Office' },
  { value: 'hospital', label: 'Hospital' },
  { value: 'pharmacy', label: 'Pharmacy' },
  { value: 'newspaper-stand', label: 'Newspaper Stand' },
  { value: 'kiosk', label: 'Kiosk' },
  { value: 'cafe', label: 'Cafe' },
];

class Selector extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log('Option selected:', this.state.selectedOption),
    );
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
        isMulti
        placeholder="Site Type"
      />
    );
  }
}

const OrganizationSites = () => {
  const [open, openBottomSheet] = useState(false);
  return (
    <>
      <SwipeableBottomSheet
        open={open}
        onChange={openBottomSheet.bind(this)}
        marginTop="20"
        style={{ zIndex: '10' }}
        bodyStyle={{
          borderTopLeftRadius: '1rem',
          borderTopRightRadius: '1rem',
        }}
        fullScreen
      >
        <div className="PrimaryTheme">
          <div className="container-sm">
            <div className="row">
              <div className="col-12 pt-8 py-lg-8">
                <h3 className="mb-4">Create a new site</h3>
                <p className="mb-6  text-muted">
                  A site a physical location that can be composed of members
                  manage and administer QR codes.
                </p>
                <h3 className="mb-4">Where is your site located?</h3>
                <form className="mb-4">
                  <div className="form-label-group mb-6">
                    <input
                      type="text"
                      className="form-control form-control-flush"
                      placeholder="Site Location"
                    />
                    <label htmlFor="cardEmail">Site Location</label>
                  </div>
                  <Selector />
                </form>
                <button
                  className="btn btn-block btn-primary mt-6 lift"
                  onClick={() => openBottomSheet(false)}
                >
                  Create new site
                </button>
                <div className="text-center">
                  <button
                    className="btn"
                    onClick={() => openBottomSheet(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwipeableBottomSheet>
      <div
        className="PrimaryTheme"
        style={{ overflowX: 'hidden', minHeight: '100vh' }}
      >
        <section>
          <div className="container-sm">
            <div className="row">
              <div className="col-12 pt-4 py-lg-8">
                <div className="row">
                  <div className="col">
                    <h2 className="mb-4">
                      <Link
                        to="/dashboard/business"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <i className="fe fe-arrow-left mr-2" />
                      </Link>
                      Sites
                    </h2>
                  </div>
                  <div className="col-auto">
                    <span className="badge badge-pill badge-primary-soft">
                      <span className="h6 text-uppercase font-weight-bold">
                        Admin only
                      </span>
                    </span>
                  </div>
                </div>
                <h4 className="text-muted ml-4">Harvard Community</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 pt-4 pb-0">
                <div className="row">
                  <div className="col">
                    <h3 className="card-title text-body mb-0 ml-4">
                      Average Site Exposure
                    </h3>
                  </div>
                  <div className="col-auto">
                    <i
                      className="fe fe-info"
                      onClick={() => openBottomSheet(true)}
                    />
                  </div>
                </div>
                <BarChartExposure />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 p-3">
                <TableSiteComponent />
              </div>
              <div className="col-12 col-lg-6 pb-3 px-3">
                <div
                  className="card"
                  style={{
                    backgroundColor: 'transparent',
                    border: '1px #c1c1c1 dashed',
                  }}
                >
                  <div className="card-body text-center">
                    <button
                      className="btn btn-primary my-4"
                      onClick={() => openBottomSheet(true)}
                    >
                      <i className="fe fe-plus mr-2" />
                      New Site
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default withRouter(OrganizationSites);
