/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
//import axios from 'axios';

import CardSlider from '../../components/CardSlider';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

import '../../../assets/css/primary.theme.css';

const MedicalDashboard = () => {
  const [open, openBottomSheet] = useState(false);
  return (
    <>
      <SwipeableBottomSheet
        open={open}
        onChange={openBottomSheet.bind(this)}
        marginTop="20"
        style={{ zIndex: '10' }}
        bodyStyle={{
          borderTopLeftRadius: '1rem',
          borderTopRightRadius: '1rem',
        }}
        fullScreen
      >
        <div className="PrimaryTheme">
          <div className="container-sm">
            <div className="row">
              <div className="col-12 pt-8 py-lg-8">
                <h3 className="mb-4">Recommendations</h3>
                <p className="mb-6  text-muted">
                  Touchpoints such as the front door, cashier, loading dock are
                  great places for QRs. You can add a nickname such as "Cash
                  Register 1"
                </p>
                <h3 className="mb-4">How is exposure calculated?</h3>
                <p className="mb-6  text-muted">
                  Touchpoints such as the front door, cashier, loading dock are
                  great places for QRs. You can add a nickname such as "Cash
                  Register 1"
                </p>
                <button
                  className="btn btn-primary btn-block my-4"
                  onClick={() => openBottomSheet(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </SwipeableBottomSheet>
      <div className="PrimaryTheme" style={{ overflowX: 'hidden' }}>
        <section>
          <div className="container-sm">
            <div className="row">
              <div className="col-12 pt-4 py-lg-8">
                <div className="row">
                  <div className="col">
                    <h2 className="mb-4">
                      <Link
                        to="/dashboard/business/codes"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <i className="fe fe-arrow-left mr-2" />
                      </Link>
                      Welcome Back!
                    </h2>
                  </div>
                  <div className="col-auto">
                    <span className="badge badge-pill badge-primary-soft">
                      <span className="h6 text-uppercase font-weight-bold">
                        Medical view
                      </span>
                    </span>
                  </div>
                </div>
                <h4 className="text-muted">Kawempe General Hospital</h4>
              </div>
              <div className="col-6 col-lg-3 py-3 pl-3 pr-0">
                <div className="card shadow-dark">
                  <Link
                    className="card-body p-5 lift"
                    style={{ minHeight: '6.5rem' }}
                    to="/dashboard/member/codes"
                  >
                    <i className="fe fe-aperture" />
                    <p className="card-text-sm card-text">Update QR status</p>
                    <button
                      className="btn btn-primary btn-block btn-sm"
                      style={{ fontSize: '0.8rem' }}
                    >
                      Scan QR
                    </button>
                  </Link>
                </div>
                <div className="card bg-primary text-white shadow-dark mt-3">
                  <Link
                    className="card-body p-5 lift"
                    style={{ minHeight: '6.5rem' }}
                    to="/dashboard/medical/update/1"
                  >
                    <i className="fe fe-aperture" />
                    <p className="card-text-sm card-text">
                      Update patient status
                    </p>
                    <button
                      className="btn btn-light btn-block btn-sm"
                      style={{
                        fontSize: '0.8rem',
                        backgroundColor: 'rgba(255, 255, 255, 0.48)',
                      }}
                    >
                      Scan patient
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-lg-3 p-3">
                <div className="card shadow-dark">
                  <Link
                    className="card-body p-5 lift"
                    style={{ minHeight: '6.5rem' }}
                    to="/dashboard/business"
                  >
                    <i className="fe fe-thermometer" />
                    <i className="fe fe-clipboard" />
                    <p className="card-text-sm card-text">Symptom report</p>
                    <button
                      className="btn btn-primary btn-block btn-sm"
                      style={{ fontSize: '0.8rem' }}
                    >
                      Scan patient
                    </button>
                  </Link>
                </div>
                <div className="card shadow-dark mt-3">
                  <Link
                    className="card-body p-5 lift"
                    style={{ minHeight: '6.5rem' }}
                    to="/dashboard/business"
                  >
                    <i className="fe fe-copy" />
                    <p className="card-text-sm card-text">
                      Generate Single Use
                    </p>
                    <button
                      className="btn btn-primary btn-block btn-sm"
                      style={{ fontSize: '0.8rem' }}
                    >
                      Generate QR
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 py-6 py-lg-8">
                <div className="row">
                  <div className="col">
                    <h3 className="card-title text-body">Recommendations</h3>
                  </div>
                  <div className="col-auto">
                    <i
                      className="fe fe-info"
                      onClick={() => openBottomSheet(true)}
                    />
                  </div>
                </div>
                <CardSlider />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default withRouter(MedicalDashboard);
