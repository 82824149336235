// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../../assets/components/scanResult.css';
import '../../../assets/css/primary.theme.css';

class ScanResult extends React.Component {
  componentDidMount() {
    setTimeout(function () {
      $('#scan-notice-loading').addClass('d-none');
      $('#scan-notice-success').removeClass('d-none');
    }, 300);
    window.history.replaceState(null, null, '/');
  }
  render() {
    return (
      <div className="PrimaryTheme">
        <div className="text-center">
          <div className="vertical-center" id="scan-notice-loading">
            <div
              className="spinner-border text-gray"
              role="status"
              style={{ width: '3.5rem', height: '3.5rem' }}
            />
            <h2 className="pt-4">Connecting...</h2>
            <div className="text-muted">This will only take a moment</div>
          </div>
          <div className="d-none" id="scan-notice-success">
            <div className="wrapper green">
              <div className="header__wrapper">
                <div className="header">
                  <div className="sign">
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="card shadow-lg mt-6 mb-6 mb-xl-0">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <span className="h2 align-self-end mb-5">
                      You've formed a plink!
                    </span>
                  </div>
                  <p className="text-center text-muted mb-5">
                    Thank you for contributing to your community's public health
                    defense!
                  </p>
                  <a href="/" className="btn btn-block btn-success-soft">
                    Continue<i className="fe fe-arrow-right ml-3"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ScanResult);
