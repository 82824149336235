import React, { useState, createContext } from 'react';

const ORGANIZATION_ID_KEY = 'organizationId';
const SITE_ID_KEY = 'siteId';

export const DashboardContext = createContext();
export const DashboardContextProvider = ({ children }) => {
  const [state, setState] = useState({
    organizationId: localStorage.getItem(ORGANIZATION_ID_KEY),
    siteId: localStorage.getItem(SITE_ID_KEY),
  });

  return (
    <DashboardContext.Provider
      value={{
        // ...state,
        // setDashboardContext: (organization, site) => {
        //   setState({
        //     ...state,
        //     organizationId: organization,
        //     siteId: site,
        //   });
        //   localStorage.setItem(ORGANIZATION_ID_KEY, organization);
        //   localStorage.setItem(SITE_ID_KEY, site);
        // },
        ...state,
        setSite: (id) => {
          setState({
            ...state,
            siteId: id,
          });
          localStorage.setItem(SITE_ID_KEY, id);
        },
        setOrganization: (id) => {
          setState({
            ...state,
            organizationId: id,
          });
          localStorage.setItem(ORGANIZATION_ID_KEY, id);
        },
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
