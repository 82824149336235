import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { DashboardContext } from '../../../shared/DashboardContext';
import { AuthenticationContext } from '../../../shared/AuthenticationContext';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import '../../../../assets/css/primary.theme.css';

function CountDown() {
  const [counter, setCounter] = useState(20);

  // Third Attempts
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return <span>{counter}</span>;
}

const SiteMemberScannables = () => {
  const [open, openBottomSheet] = useState(false);
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <DashboardContext.Consumer>
          {({ organizationId, siteId, setOrganization, setSite }) => (
            <>
              <SwipeableBottomSheet
                open={open}
                onChange={openBottomSheet.bind(this)}
                marginTop="20"
                style={{ zIndex: '10' }}
                bodyStyle={{
                  borderTopLeftRadius: '1rem',
                  borderTopRightRadius: '1rem',
                }}
                fullScreen
              >
                <div className="PrimaryTheme">
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-8 py-lg-8">
                        <h3 className="mb-4">Cash Register 1</h3>
                        <p className="text-muted">
                          If the physical QR is not available or damaged, please
                          show the individual this QR for scanning. If the
                          individual has a physical QR, please scan them
                          instead.
                        </p>
                        <p className="mb-4 text-muted">
                          This QR will automatically refresh in <CountDown />
                        </p>
                        <div className="text-center">
                          <QRCode
                            value={
                              'https://www.plinks.io/s/5553d936-11ec-4e26-8d7a-452df697474d'
                            }
                            size={310}
                            bgColor={'#ffffff'}
                            fgColor={'#000000'}
                            level={'L'}
                            includeMargin={true}
                            renderAs={'svg'}
                          />
                        </div>
                        <button
                          className="btn btn-block btn-primary mb-4 lift"
                          onClick={() => this.props.history.push('/scan')}
                        >
                          Scan as QR instead
                        </button>
                        <div className="text-center">
                          <button
                            className="btn"
                            onClick={() => openBottomSheet(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwipeableBottomSheet>
              <div
                className="PrimaryTheme"
                style={{ overflowX: 'hidden', minHeight: '100vh' }}
              >
                <section>
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-4 py-lg-8">
                        <h2 className="mb-4">
                          <Link
                            to="/dashboard/member"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <i className="fe fe-arrow-left mr-2" />
                          </Link>
                          QR Codes
                        </h2>
                        <h4>311 Winthrop Drive, Cambridge MA 02138</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 p-3">
                        <div className="card shadow-dark">
                          <div className="card-body d-flex align-items-center">
                            <div className="row w-100 m-0">
                              <div className="col-auto p-0">
                                <div className="avatar avatar-xl">
                                  <img
                                    src="../../assets/img/avatars/qr-small.jpg"
                                    alt="..."
                                    className="avatar-img rounded"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <input
                                  type="text"
                                  className="form-control form-control-flush"
                                  placeholder="QR Code Name"
                                  value="Front Door"
                                />
                              </div>
                              <div className="col-auto p-0">
                                <button
                                  onClick={() => openBottomSheet(true)}
                                  className="btn lift"
                                >
                                  <i className="fe fe-eye" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 pb-3 px-3">
                        <div className="card shadow-dark">
                          <div className="card-body d-flex align-items-center">
                            <div className="row w-100 m-0">
                              <div className="col-auto p-0">
                                <div className="avatar avatar-xl">
                                  <img
                                    src="../../assets/img/avatars/qr-small.jpg"
                                    alt="..."
                                    className="avatar-img rounded"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <input
                                  type="text"
                                  className="form-control form-control-flush"
                                  placeholder="QR Code Name"
                                  value="Cash Register 1"
                                />
                              </div>
                              <div className="col-auto p-0">
                                <button
                                  onClick={() => openBottomSheet(true)}
                                  className="btn lift"
                                >
                                  <i className="fe fe-eye" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </DashboardContext.Consumer>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default withRouter(SiteMemberScannables);
