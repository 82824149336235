/* eslint-disable jsx-a11y/alt-text */
// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import Typed from 'typed.js';
import AOS from 'aos';
import 'aos/dist/aos.css';

import '../../assets/css/extensions.min.css';
import '../../assets/css/landing.theme.css';
import '../../assets/css/swipeable.css';

class Home extends React.Component {
  componentDidMount() {
    AOS.init({
      duration: 700,
      easing: 'ease-out-quad',
      once: !0,
      startEvent: 'load',
    });
    const options = {
      strings: ['Individuals', 'Communities', 'Public Health', 'Enterprises'],
      typeSpeed: 30,
      backSpeed: 30,
      backDelay: 2e3,
      loop: !0,
    };
    this.typed = new Typed(this.el, options);
  }
  componentWillUnmount() {
    // Make sure to destroy Typed instance on unmounting
    // to prevent memory leaks
    this.typed.destroy();
  }
  render() {
    return (
      <>
        <div
          className="LandingTheme"
          style={{
            position: 'relative',
            overflow: 'hidden',
            transform: 'translate(0)',
          }}
        >
          <div>
            <section className="pt-6 pt-md-10">
              <div className="container-lg">
                <div className="row align-items-center">
                  <div
                    className="col-md-6 col-xl-5 text-center text-md-left mt-md-n8"
                    data-aos="fade-up"
                  >
                    {/* Heading */}
                    <img
                      className="img-fluid mb-2"
                      src="assets/img/plinks-logo-2.png"
                      style={{ maxWidth: '10rem' }}
                    />
                    <h1
                      className="display-5 mb-4"
                      style={{ fontSize: '2.2rem' }}
                    >
                      A contact tracing experience for&nbsp;
                      <br className="d-sm-none" />
                      <span className="text-primary-light text-underline-primary-light d-lg-inline">
                        <span
                          ref={(el) => {
                            this.el = el;
                          }}
                        />
                      </span>
                    </h1>
                    {/* Text */}
                    <p className="font-size-lg">
                      An open source, inclusive, anonymous, complete contact
                      tracing incident platform. No app, no download, no install
                      or signup required.
                      <span className="text-primary-light">#notAnotherApp</span>
                    </p>
                    {/* Buttons */}
                    <div className="mb-8 mb-md-0">
                      <a
                        className="btn btn-primary-light lift d-sm-inline d-none"
                        href="mailto:start@plinks.io"
                      >
                        Get Started
                      </a>
                      <a
                        className="btn btn-primary-light btn-block lift d-sm-none"
                        href="/scan"
                      >
                        Scan
                      </a>
                      <a
                        className="btn btn-white lift btn-block d-sm-none mt-4"
                        href="#why-us"
                      >
                        Why Us?
                      </a>
                      <a
                        className="btn btn-white lift ml-3 d-sm-inline d-none"
                        href="#why-us"
                      >
                        Why Us?
                      </a>
                    </div>
                  </div>
                  <div className="col-md-5 offset-md-1 offset-xl-2 align-self-start d-none d-md-block">
                    {/* Blur */}
                    <img
                      className="position-absolute img-fluid mw-125 mt-n9"
                      src="assets/img/blurs/blur-1.svg"
                      alt="..."
                      data-aos="fade-up"
                      data-aos-delay={100}
                    />
                  </div>
                  <div className="col-md-4 offset-md-n4">
                    {/* Image */}
                    <div className="position-relative">
                      {/* Decoration */}
                      <div
                        className="position-absolute bottom-right text-primary-light mr-n8 mb-n6"
                        data-aos="fade-up"
                        data-aos-delay={150}
                      >
                        <svg
                          width={185}
                          height={186}
                          viewBox="0 0 185 186"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx={2} cy={2} r={2} fill="currentColor" />
                          <circle cx={22} cy={2} r={2} fill="currentColor" />
                          <circle cx={42} cy={2} r={2} fill="currentColor" />
                          <circle cx={62} cy={2} r={2} fill="currentColor" />
                          <circle cx={82} cy={2} r={2} fill="currentColor" />
                          <circle cx={102} cy={2} r={2} fill="currentColor" />
                          <circle cx={122} cy={2} r={2} fill="currentColor" />
                          <circle cx={142} cy={2} r={2} fill="currentColor" />
                          <circle cx={162} cy={2} r={2} fill="currentColor" />
                          <circle cx={182} cy={2} r={2} fill="currentColor" />
                          <circle cx={2} cy={22} r={2} fill="currentColor" />
                          <circle cx={22} cy={22} r={2} fill="currentColor" />
                          <circle cx={42} cy={22} r={2} fill="currentColor" />
                          <circle cx={62} cy={22} r={2} fill="currentColor" />
                          <circle cx={82} cy={22} r={2} fill="currentColor" />
                          <circle cx={102} cy={22} r={2} fill="currentColor" />
                          <circle cx={122} cy={22} r={2} fill="currentColor" />
                          <circle cx={142} cy={22} r={2} fill="currentColor" />
                          <circle cx={162} cy={22} r={2} fill="currentColor" />
                          <circle cx={182} cy={22} r={2} fill="currentColor" />
                          <circle cx={2} cy={42} r={2} fill="currentColor" />
                          <circle cx={22} cy={42} r={2} fill="currentColor" />
                          <circle cx={42} cy={42} r={2} fill="currentColor" />
                          <circle cx={62} cy={42} r={2} fill="currentColor" />
                          <circle cx={82} cy={42} r={2} fill="currentColor" />
                          <circle cx={102} cy={42} r={2} fill="currentColor" />
                          <circle cx={122} cy={42} r={2} fill="currentColor" />
                          <circle cx={142} cy={42} r={2} fill="currentColor" />
                          <circle cx={162} cy={42} r={2} fill="currentColor" />
                          <circle cx={182} cy={42} r={2} fill="currentColor" />
                          <circle cx={2} cy={62} r={2} fill="currentColor" />
                          <circle cx={22} cy={62} r={2} fill="currentColor" />
                          <circle cx={42} cy={62} r={2} fill="currentColor" />
                          <circle cx={62} cy={62} r={2} fill="currentColor" />
                          <circle cx={82} cy={62} r={2} fill="currentColor" />
                          <circle cx={102} cy={62} r={2} fill="currentColor" />
                          <circle cx={122} cy={62} r={2} fill="currentColor" />
                          <circle cx={142} cy={62} r={2} fill="currentColor" />
                          <circle cx={162} cy={62} r={2} fill="currentColor" />
                          <circle cx={182} cy={62} r={2} fill="currentColor" />
                          <circle cx={2} cy={82} r={2} fill="currentColor" />
                          <circle cx={22} cy={82} r={2} fill="currentColor" />
                          <circle cx={42} cy={82} r={2} fill="currentColor" />
                          <circle cx={62} cy={82} r={2} fill="currentColor" />
                          <circle cx={82} cy={82} r={2} fill="currentColor" />
                          <circle cx={102} cy={82} r={2} fill="currentColor" />
                          <circle cx={122} cy={82} r={2} fill="currentColor" />
                          <circle cx={142} cy={82} r={2} fill="currentColor" />
                          <circle cx={162} cy={82} r={2} fill="currentColor" />
                          <circle cx={182} cy={82} r={2} fill="currentColor" />
                          <circle cx={2} cy={102} r={2} fill="currentColor" />
                          <circle cx={22} cy={102} r={2} fill="currentColor" />
                          <circle cx={42} cy={102} r={2} fill="currentColor" />
                          <circle cx={62} cy={102} r={2} fill="currentColor" />
                          <circle cx={82} cy={102} r={2} fill="currentColor" />
                          <circle cx={102} cy={102} r={2} fill="currentColor" />
                          <circle cx={122} cy={102} r={2} fill="currentColor" />
                          <circle cx={142} cy={102} r={2} fill="currentColor" />
                          <circle cx={162} cy={102} r={2} fill="currentColor" />
                          <circle cx={182} cy={102} r={2} fill="currentColor" />
                          <circle cx={2} cy={122} r={2} fill="currentColor" />
                          <circle cx={22} cy={122} r={2} fill="currentColor" />
                          <circle cx={42} cy={122} r={2} fill="currentColor" />
                          <circle cx={62} cy={122} r={2} fill="currentColor" />
                          <circle cx={82} cy={122} r={2} fill="currentColor" />
                          <circle cx={102} cy={122} r={2} fill="currentColor" />
                          <circle cx={122} cy={122} r={2} fill="currentColor" />
                          <circle cx={142} cy={122} r={2} fill="currentColor" />
                          <circle cx={162} cy={122} r={2} fill="currentColor" />
                          <circle cx={182} cy={122} r={2} fill="currentColor" />
                          <circle cx={2} cy={142} r={2} fill="currentColor" />
                          <circle cx={22} cy={142} r={2} fill="currentColor" />
                          <circle cx={42} cy={142} r={2} fill="currentColor" />
                          <circle cx={62} cy={142} r={2} fill="currentColor" />
                          <circle cx={82} cy={142} r={2} fill="currentColor" />
                          <circle cx={102} cy={142} r={2} fill="currentColor" />
                          <circle cx={122} cy={142} r={2} fill="currentColor" />
                          <circle cx={142} cy={142} r={2} fill="currentColor" />
                          <circle cx={162} cy={142} r={2} fill="currentColor" />
                          <circle cx={182} cy={142} r={2} fill="currentColor" />
                          <circle cx={2} cy={162} r={2} fill="currentColor" />
                          <circle cx={22} cy={162} r={2} fill="currentColor" />
                          <circle cx={42} cy={162} r={2} fill="currentColor" />
                          <circle cx={62} cy={162} r={2} fill="currentColor" />
                          <circle cx={82} cy={162} r={2} fill="currentColor" />
                          <circle cx={102} cy={162} r={2} fill="currentColor" />
                          <circle cx={122} cy={162} r={2} fill="currentColor" />
                          <circle cx={142} cy={162} r={2} fill="currentColor" />
                          <circle cx={162} cy={162} r={2} fill="currentColor" />
                          <circle cx={182} cy={162} r={2} fill="currentColor" />
                          <circle cx={2} cy={182} r={2} fill="currentColor" />
                          <circle cx={22} cy={182} r={2} fill="currentColor" />
                          <circle cx={42} cy={182} r={2} fill="currentColor" />
                          <circle cx={62} cy={182} r={2} fill="currentColor" />
                          <circle cx={82} cy={182} r={2} fill="currentColor" />
                          <circle cx={102} cy={182} r={2} fill="currentColor" />
                          <circle cx={122} cy={182} r={2} fill="currentColor" />
                          <circle cx={142} cy={182} r={2} fill="currentColor" />
                          <circle cx={162} cy={182} r={2} fill="currentColor" />
                          <circle cx={182} cy={182} r={2} fill="currentColor" />
                        </svg>
                      </div>
                      {/* Image */}
                      <div
                        className="flickity-buttons-adjacent flickity-buttons-bottom-left"
                        data-flickity='{"imagesLoaded": true, "prevNextButtons": true, "pageDots": false, "wrapAround": true, "autoPlay": true}'
                      >
                        <div className="w-100">
                          <img
                            className="img-fluid mw-75 mw-md-100 rounded-top-left rounded-bottom-right shadow-lg"
                            src="assets/img/scanner/card.jpg"
                            alt="Plinks QR on a card"
                            data-aos="wipe-right"
                            data-aos-delay={100}
                          />
                        </div>
                        <div className="w-100">
                          <img
                            className="img-fluid mw-75 mw-md-100 rounded-top-left rounded-bottom-right shadow-lg"
                            src="assets/img/scanner/phone.jpg"
                            alt="Plinks QR on a smartphone P2P"
                          />
                        </div>
                        <div className="w-100">
                          <img
                            className="img-fluid mw-75 mw-md-100 rounded-top-left rounded-bottom-right shadow-lg"
                            src="assets/img/scanner/transport.jpg"
                            alt="Plinks QR on transportation"
                          />
                        </div>
                        <div className="w-100">
                          <img
                            className="img-fluid mw-75 mw-md-100 rounded-top-left rounded-bottom-right shadow-lg"
                            src="assets/img/scanner/store.jpg"
                            alt="Plinks QR at a store"
                          />
                        </div>
                      </div>
                      {/* <div className="text-center">
                <img className="position-relative img-fluid mw-75 mw-md-100 rounded-top-left rounded-bottom-right shadow-lg" src="assets/img/scanner/phone.jpg" alt="..." data-aos="fade-up" data-aos-delay="200">
              </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* ABOUT */}
            <section className="pt-10 pt-md-12">
              <div className="container-lg">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-5">
                    {/* Image grid */}
                    <div className="img-grid img-grid-1 mb-8 mb-md-0">
                      <div className="img-grid-item">
                        {/* Image */}
                        <img
                          className="img-fluid rounded-top-left-lg rounded-bottom-right-lg shadow-lg"
                          data-jarallax-element={-30}
                          src="assets/img/avatars/avatar-jumbo-1.jpg"
                          alt="..."
                        />
                      </div>
                      <div className="img-grid-item">
                        {/* Image */}
                        <img
                          className="img-fluid rounded-top-left rounded-bottom-right shadow-lg"
                          data-jarallax-element={-85}
                          src="assets/img/avatars/avatar-2.jpg"
                          alt="..."
                        />
                      </div>
                      <div className="img-grid-item">
                        {/* Image */}
                        <img
                          className="img-fluid rounded-top-left rounded-bottom-right shadow-lg"
                          data-jarallax-element={-45}
                          src="assets/img/avatars/avatar-8.jpg"
                          alt="..."
                        />
                      </div>
                      <div className="img-grid-item">
                        {/* Image */}
                        <img
                          className="img-fluid rounded-top-left rounded-bottom-right shadow-lg"
                          data-jarallax-element={-50}
                          src="assets/img/avatars/avatar-10.jpg"
                          alt="..."
                        />
                      </div>
                      <div className="img-grid-item">
                        {/* Image */}
                        <img
                          className="img-fluid rounded-top-left rounded-bottom-right shadow-lg"
                          data-jarallax-element={-20}
                          src="assets/img/avatars/avatar-6.jpg"
                          alt="..."
                        />
                      </div>
                      <div className="img-grid-item">
                        {/* Image */}
                        <img
                          className="img-fluid rounded-top-left rounded-bottom-right shadow-lg"
                          data-jarallax-element={-15}
                          src="assets/img/avatars/avatar-4.jpg"
                          alt="..."
                        />
                      </div>
                      <div className="img-grid-item">
                        {/* Image */}
                        <img
                          className="img-fluid rounded-top-left rounded-bottom-right shadow-lg"
                          data-jarallax-element={-10}
                          src="assets/img/avatars/avatar-3.jpg"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-5 text-center text-md-left">
                    {/* Heading */}
                    <h2 className="display-5 mb-4">
                      Contribute to your community's public health with just a
                      plink.
                    </h2>
                    {/* Text */}
                    <p className="text-muted mb-4">
                      A plink (private link) enables communities to perform
                      contact tracing in an extremely private way. The more
                      plinks a community builds up, the better prepared they are
                      to assess, administer, and notify.
                    </p>
                    <p className="text-muted mb-0">
                      Plinks place people first, we believe privacy is a
                      fundamental value that does not hinder the ability to
                      administer public health within organizations,
                      communities, or states.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* ABOUT */}
            <section className="py-10 py-md-12">
              <div className="container-lg">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6 order-md-1">
                    {/* Image */}
                    <img
                      className="img-fluid mb-6 mb-md-0"
                      src="assets/img/printedQR2.png"
                      alt="You can print out a Plinks formatted QR"
                    />
                  </div>
                  <div className="col-md-6 col-xl-5 order-md-0 text-center text-md-left">
                    {/* Heading */}
                    <h2 className="display-5 mb-6">
                      Simple, accessible contact tracing for all.
                    </h2>
                    {/* Item */}
                    <div className="row">
                      <div className="col-12 col-md-auto">
                        {/* Icon */}
                        <div className="icon text-primary-light mb-2">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fillRule="evenodd">
                              <path d="M0 0h24v24H0z" />
                              <path
                                d="M6 9v6a3 3 0 003 3h6v.818C15 20.232 14.232 21 12.818 21H5.182C3.768 21 3 20.232 3 18.818v-7.636C3 9.768 3.768 9 5.182 9H6z"
                                fill="#335EEA"
                              />
                              <path
                                d="M10.182 4h7.636C19.232 4 20 4.768 20 6.182v7.636C20 15.232 19.232 16 17.818 16h-7.636C8.768 16 8 15.232 8 13.818V6.182C8 4.768 8.768 4 10.182 4z"
                                fill="#335EEA"
                                opacity=".3"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="col">
                        {/* Title */}
                        <p className="font-size-lg font-weight-bold mb-2">
                          No phone required
                        </p>
                        {/* Text */}
                        <p className="text-muted mb-5">
                          We designed Plinks from the ground up, to empower all
                          communities regardless of resource. No phone? No
                          problem - physical QRs can be printed and scanned to
                          form a plink.
                        </p>
                      </div>
                    </div>
                    {/* Item */}
                    <div className="row">
                      <div className="col-12 col-md-auto">
                        {/* Icon */}
                        <div className="icon text-primary-light mb-2">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fillRule="evenodd">
                              <path d="M0 0h24v24H0z" />
                              <path
                                d="M4 4l7.631-1.43a2 2 0 01.738 0L20 4v9.283a8.51 8.51 0 01-4 7.217l-3.47 2.169a1 1 0 01-1.06 0L8 20.5a8.51 8.51 0 01-4-7.217V4z"
                                fill="#335EEA"
                                opacity=".3"
                              />
                              <path
                                d="M11.175 14.75a.946.946 0 01-.67-.287l-1.917-1.917a.926.926 0 010-1.342c.383-.383 1.006-.383 1.341 0l1.246 1.246 3.163-3.162a.926.926 0 011.341 0 .926.926 0 010 1.341l-3.833 3.834a.946.946 0 01-.671.287z"
                                fill="#335EEA"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="col">
                        {/* Title */}
                        <p className="font-size-lg font-weight-bold mb-2">
                          Enterprise Security
                        </p>
                        {/* Text */}
                        <p className="text-muted mb-0">
                          The best security is having nothing to steal. We never
                          ask individuals for any personally identifable
                          information. We secure each managed or self hosted
                          instance with industry best practices.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="pb-11 pb-md-13">
              <div className="container-lg">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-5 col-lg-6 order-md-1">
                    {/* Image */}
                    <div className="position-relative mb-10 mb-md-0">
                      {/* Decoration */}
                      <div className="position-absolute bottom-left text-primary-light ml-n8 mb-n8">
                        <svg
                          width={185}
                          height={186}
                          viewBox="0 0 185 186"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx={2} cy={2} r={2} fill="currentColor" />
                          <circle cx={22} cy={2} r={2} fill="currentColor" />
                          <circle cx={42} cy={2} r={2} fill="currentColor" />
                          <circle cx={62} cy={2} r={2} fill="currentColor" />
                          <circle cx={82} cy={2} r={2} fill="currentColor" />
                          <circle cx={102} cy={2} r={2} fill="currentColor" />
                          <circle cx={122} cy={2} r={2} fill="currentColor" />
                          <circle cx={142} cy={2} r={2} fill="currentColor" />
                          <circle cx={162} cy={2} r={2} fill="currentColor" />
                          <circle cx={182} cy={2} r={2} fill="currentColor" />
                          <circle cx={2} cy={22} r={2} fill="currentColor" />
                          <circle cx={22} cy={22} r={2} fill="currentColor" />
                          <circle cx={42} cy={22} r={2} fill="currentColor" />
                          <circle cx={62} cy={22} r={2} fill="currentColor" />
                          <circle cx={82} cy={22} r={2} fill="currentColor" />
                          <circle cx={102} cy={22} r={2} fill="currentColor" />
                          <circle cx={122} cy={22} r={2} fill="currentColor" />
                          <circle cx={142} cy={22} r={2} fill="currentColor" />
                          <circle cx={162} cy={22} r={2} fill="currentColor" />
                          <circle cx={182} cy={22} r={2} fill="currentColor" />
                          <circle cx={2} cy={42} r={2} fill="currentColor" />
                          <circle cx={22} cy={42} r={2} fill="currentColor" />
                          <circle cx={42} cy={42} r={2} fill="currentColor" />
                          <circle cx={62} cy={42} r={2} fill="currentColor" />
                          <circle cx={82} cy={42} r={2} fill="currentColor" />
                          <circle cx={102} cy={42} r={2} fill="currentColor" />
                          <circle cx={122} cy={42} r={2} fill="currentColor" />
                          <circle cx={142} cy={42} r={2} fill="currentColor" />
                          <circle cx={162} cy={42} r={2} fill="currentColor" />
                          <circle cx={182} cy={42} r={2} fill="currentColor" />
                          <circle cx={2} cy={62} r={2} fill="currentColor" />
                          <circle cx={22} cy={62} r={2} fill="currentColor" />
                          <circle cx={42} cy={62} r={2} fill="currentColor" />
                          <circle cx={62} cy={62} r={2} fill="currentColor" />
                          <circle cx={82} cy={62} r={2} fill="currentColor" />
                          <circle cx={102} cy={62} r={2} fill="currentColor" />
                          <circle cx={122} cy={62} r={2} fill="currentColor" />
                          <circle cx={142} cy={62} r={2} fill="currentColor" />
                          <circle cx={162} cy={62} r={2} fill="currentColor" />
                          <circle cx={182} cy={62} r={2} fill="currentColor" />
                          <circle cx={2} cy={82} r={2} fill="currentColor" />
                          <circle cx={22} cy={82} r={2} fill="currentColor" />
                          <circle cx={42} cy={82} r={2} fill="currentColor" />
                          <circle cx={62} cy={82} r={2} fill="currentColor" />
                          <circle cx={82} cy={82} r={2} fill="currentColor" />
                          <circle cx={102} cy={82} r={2} fill="currentColor" />
                          <circle cx={122} cy={82} r={2} fill="currentColor" />
                          <circle cx={142} cy={82} r={2} fill="currentColor" />
                          <circle cx={162} cy={82} r={2} fill="currentColor" />
                          <circle cx={182} cy={82} r={2} fill="currentColor" />
                          <circle cx={2} cy={102} r={2} fill="currentColor" />
                          <circle cx={22} cy={102} r={2} fill="currentColor" />
                          <circle cx={42} cy={102} r={2} fill="currentColor" />
                          <circle cx={62} cy={102} r={2} fill="currentColor" />
                          <circle cx={82} cy={102} r={2} fill="currentColor" />
                          <circle cx={102} cy={102} r={2} fill="currentColor" />
                          <circle cx={122} cy={102} r={2} fill="currentColor" />
                          <circle cx={142} cy={102} r={2} fill="currentColor" />
                          <circle cx={162} cy={102} r={2} fill="currentColor" />
                          <circle cx={182} cy={102} r={2} fill="currentColor" />
                          <circle cx={2} cy={122} r={2} fill="currentColor" />
                          <circle cx={22} cy={122} r={2} fill="currentColor" />
                          <circle cx={42} cy={122} r={2} fill="currentColor" />
                          <circle cx={62} cy={122} r={2} fill="currentColor" />
                          <circle cx={82} cy={122} r={2} fill="currentColor" />
                          <circle cx={102} cy={122} r={2} fill="currentColor" />
                          <circle cx={122} cy={122} r={2} fill="currentColor" />
                          <circle cx={142} cy={122} r={2} fill="currentColor" />
                          <circle cx={162} cy={122} r={2} fill="currentColor" />
                          <circle cx={182} cy={122} r={2} fill="currentColor" />
                          <circle cx={2} cy={142} r={2} fill="currentColor" />
                          <circle cx={22} cy={142} r={2} fill="currentColor" />
                          <circle cx={42} cy={142} r={2} fill="currentColor" />
                          <circle cx={62} cy={142} r={2} fill="currentColor" />
                          <circle cx={82} cy={142} r={2} fill="currentColor" />
                          <circle cx={102} cy={142} r={2} fill="currentColor" />
                          <circle cx={122} cy={142} r={2} fill="currentColor" />
                          <circle cx={142} cy={142} r={2} fill="currentColor" />
                          <circle cx={162} cy={142} r={2} fill="currentColor" />
                          <circle cx={182} cy={142} r={2} fill="currentColor" />
                          <circle cx={2} cy={162} r={2} fill="currentColor" />
                          <circle cx={22} cy={162} r={2} fill="currentColor" />
                          <circle cx={42} cy={162} r={2} fill="currentColor" />
                          <circle cx={62} cy={162} r={2} fill="currentColor" />
                          <circle cx={82} cy={162} r={2} fill="currentColor" />
                          <circle cx={102} cy={162} r={2} fill="currentColor" />
                          <circle cx={122} cy={162} r={2} fill="currentColor" />
                          <circle cx={142} cy={162} r={2} fill="currentColor" />
                          <circle cx={162} cy={162} r={2} fill="currentColor" />
                          <circle cx={182} cy={162} r={2} fill="currentColor" />
                          <circle cx={2} cy={182} r={2} fill="currentColor" />
                          <circle cx={22} cy={182} r={2} fill="currentColor" />
                          <circle cx={42} cy={182} r={2} fill="currentColor" />
                          <circle cx={62} cy={182} r={2} fill="currentColor" />
                          <circle cx={82} cy={182} r={2} fill="currentColor" />
                          <circle cx={102} cy={182} r={2} fill="currentColor" />
                          <circle cx={122} cy={182} r={2} fill="currentColor" />
                          <circle cx={142} cy={182} r={2} fill="currentColor" />
                          <circle cx={162} cy={182} r={2} fill="currentColor" />
                          <circle cx={182} cy={182} r={2} fill="currentColor" />
                        </svg>
                      </div>
                      {/* Image */}
                      <div className="rounded-top-left rounded-bottom-right shadow-img">
                        <img
                          className="position-relative img-fluid rounded-top-left rounded-bottom-right shadow-lg"
                          src="assets/img/plinks-dashboard.jpg"
                          alt="Plinks administrative dashboard"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-5 order-md-0">
                    {/* Heading */}
                    <h2 className="display-5 mb-4">
                      Measure to decide, not just to measure.
                    </h2>
                    {/* Text */}
                    <p>
                      Private links ensure anonymity, while also providing
                      actionable data for healthcare entities at each stage. We
                      enable big data without big brother.
                    </p>
                    {/* List */}
                    <ul className="list-checked list-checked-primary">
                      <li>Graph Exploration</li>
                      <li>Key Statistics</li>
                      <li>Machine learning enabled projections</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            {/* FEATURES */}
            <section className="pt-10 pt-md-12 pb-10 bg-dark" id="why-us">
              <div className="container-lg">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-8">
                    {/* Heading */}
                    <h2 className="display-4 text-center text-white mb-9 mb-md-10">
                      The right match for you.
                    </h2>
                  </div>
                </div>
                <div className="row mt-n8 mt-md-n9" data-aos="fade-up">
                  <div className="col-md-6 col-lg-4">
                    {/* Item */}
                    <div className="d-flex mt-8 mt-md-9">
                      {/* Icon */}
                      <div className="icon text-primary-light">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path d="M0 0h24v24H0z" />
                            <path
                              d="M4 4l7.631-1.43a2 2 0 01.738 0L20 4v9.283a8.51 8.51 0 01-4 7.217l-3.47 2.169a1 1 0 01-1.06 0L8 20.5a8.51 8.51 0 01-4-7.217V4z"
                              fill="#335EEA"
                              opacity=".3"
                            />
                            <path
                              d="M11.175 14.75a.946.946 0 01-.67-.287l-1.917-1.917a.926.926 0 010-1.342c.383-.383 1.006-.383 1.341 0l1.246 1.246 3.163-3.162a.926.926 0 011.341 0 .926.926 0 010 1.341l-3.833 3.834a.946.946 0 01-.671.287z"
                              fill="#335EEA"
                            />
                          </g>
                        </svg>
                      </div>
                      {/* Body */}
                      <div className="ml-5">
                        {/* Title */}
                        <p className="font-size-lg font-weight-bold text-white mb-1">
                          Anonymous
                        </p>
                        {/* Text */}
                        <p className="font-size-sm text-white-60 mb-0">
                          We place privacy first. Our contact tracing platform
                          is effective without requiring personal identifiable
                          details. Compliance is higher when users know they
                          have nothing to lose.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    {/* Item */}
                    <div className="d-flex mt-8 mt-md-9">
                      {/* Icon */}
                      <div className="icon text-primary-light">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path d="M0 0h24v24H0z" />
                            <path
                              d="M22 15.01l.025-.01v4.586a1 1 0 01-1.707.707L18.025 18H5a3 3 0 01-3-3V6a3 3 0 013-3h14a3 3 0 013 3v9.01zM6.168 10.555C7.678 12.819 9.646 14 12 14c2.354 0 4.322-1.18 5.832-3.445a1 1 0 10-1.664-1.11C15.01 11.181 13.646 12 12 12c-1.646 0-3.01-.82-4.168-2.555a1 1 0 00-1.664 1.11z"
                              fill="#335EEA"
                            />
                          </g>
                        </svg>
                      </div>
                      {/* Body */}
                      <div className="ml-5">
                        {/* Title */}
                        <p className="font-size-lg font-weight-bold text-white mb-1">
                          Cross-platform
                        </p>
                        {/* Text */}
                        <p className="font-size-sm text-white-60 mb-0">
                          Our solution scales down to mobile web browsers, with
                          coverage for most devices. Our Android app accounts
                          for 99.5% of the current devices on the market. We
                          also offer an iOS solution as well.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    {/* Item */}
                    <div className="d-flex mt-9">
                      {/* Icon */}
                      <div className="icon text-primary-light">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path d="M0 0h24v24H0z" />
                            <path
                              d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z"
                              fill="#335EEA"
                              opacity=".3"
                            />
                            <path
                              d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z"
                              fill="#335EEA"
                            />
                          </g>
                        </svg>
                      </div>
                      {/* Body */}
                      <div className="ml-5">
                        {/* Title */}
                        <p className="font-size-lg font-weight-bold text-white mb-1">
                          Administrative
                        </p>
                        {/* Text */}
                        <p className="font-size-sm text-white-60 mb-0">
                          Administrative functionality pairs with effective
                          contact tracing. We believe a plink is the minimum
                          data required for effective, but private
                          administration.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    {/* Item */}
                    <div className="d-flex mt-8 mt-md-9">
                      {/* Icon */}
                      <div className="icon text-primary-light">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path d="M0 0h24v24H0z" />
                            <path
                              d="M7 11h8a2 2 0 002-2V8h2v1a4 4 0 01-4 4H7v2a1 1 0 01-2 0V9a1 1 0 112 0v2z"
                              fill="#335EEA"
                              opacity=".3"
                            />
                            <path
                              d="M6 21a2 2 0 100-4 2 2 0 000 4zm0 2a4 4 0 110-8 4 4 0 010 8zM18 7a2 2 0 100-4 2 2 0 000 4zm0 2a4 4 0 110-8 4 4 0 010 8zM6 7a2 2 0 100-4 2 2 0 000 4zm0 2a4 4 0 110-8 4 4 0 010 8z"
                              fill="#335EEA"
                            />
                          </g>
                        </svg>
                      </div>
                      {/* Body */}
                      <div className="ml-5">
                        {/* Title */}
                        <p className="font-size-lg font-weight-bold text-white mb-1">
                          Complete
                        </p>
                        {/* Text */}
                        <p className="font-size-sm text-white-60 mb-0">
                          Plinks offers a complete, but private solution. This
                          allows for management and reporting dashboards that
                          are critical for incident awareness and assessment.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    {/* Item */}
                    <div className="d-flex mt-8 mt-md-9">
                      {/* Icon */}
                      <div className="icon text-primary-light">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path d="M0 0h24v24H0z" />
                            <path
                              d="M4 4h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2z"
                              fill="#335EEA"
                              opacity=".3"
                            />
                            <path
                              d="M18.5 11h-13A1.5 1.5 0 004 12.5v.5h4.586a1 1 0 01.707.293l1 1a2.414 2.414 0 003.414 0l1-1a1 1 0 01.707-.293H20v-.5a1.5 1.5 0 00-1.5-1.5zM5.5 6A1.5 1.5 0 004 7.5V8h16v-.5A1.5 1.5 0 0018.5 6h-13z"
                              fill="#335EEA"
                            />
                          </g>
                        </svg>
                      </div>
                      {/* Body */}
                      <div className="ml-5">
                        {/* Title */}
                        <p className="font-size-lg font-weight-bold text-white mb-1">
                          Open Source and Free
                        </p>
                        {/* Text */}
                        <p className="font-size-sm text-white-60 mb-0">
                          Plinks offers managed deployments, but we empower
                          communities with the ability to self-host. We believe
                          in empowerment, not dependency.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    {/* Item */}
                    <div className="d-flex mt-8 mt-md-9">
                      {/* Icon */}
                      <div className="icon text-primary-light">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path d="M0 0h24v24H0z" />
                            <path
                              d="M16.374 19.939l5.849-8.773A.75.75 0 0021.599 10H17V4.477a.75.75 0 00-1.374-.416l-5.849 8.773A.75.75 0 0010.401 14H15v5.523a.75.75 0 001.374.416z"
                              fill="#335EEA"
                            />
                            <path
                              d="M4.5 5h5a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3zm0 12h5a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3zm-2-6h4a1.5 1.5 0 010 3h-4a1.5 1.5 0 010-3z"
                              fill="#335EEA"
                              opacity=".3"
                            />
                          </g>
                        </svg>
                      </div>
                      {/* Body */}
                      <div className="ml-5">
                        {/* Title */}
                        <p className="font-size-lg font-weight-bold text-white mb-1">
                          Easy to Use
                        </p>
                        {/* Text */}
                        <p className="font-size-sm text-white-60 mb-0">
                          We designed Plinks to be incredibly easy to use. In
                          less than two seconds anyone can contribute to their
                          community's public health.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-12 text-center">
                    {/* Heading */}
                    <a
                      className="btn btn-white lift mt-6"
                      href="mailto:start@plinks.io"
                    >
                      Contact Us to Deploy
                    </a>
                  </div>
                </div>
              </div>
            </section>
            {/* FOOTER */}
            <footer className="footer py-8 py-md-11 bg-dark border-top border-gray-900">
              <div className="container-lg">
                <div className="row">
                  <div className="col-md-4">
                    {/* Brand */}
                    <h2 className="text-white mb-1">plinks</h2>
                    {/* Text */}
                    <p className="text-white-60">
                      Built with humanity.
                      <br />
                      start@plinks.io
                    </p>
                  </div>
                  <div className="col-6 col-md">
                    {/* Heading */}
                    <h6 className="text-uppercase text-white mb-3 mb-md-5">
                      Services
                    </h6>
                    {/* List */}
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <a
                          className="text-white-60"
                          href="mailto:start@plinks.io"
                        >
                          Managed Deployments
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white-60"
                          href="mailto:start@plinks.io"
                        >
                          Self Deployment
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white-60"
                          href="mailto:start@plinks.io"
                        >
                          Custom Requests
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 col-md">
                    {/* Heading */}
                    <h6 className="text-uppercase text-white mb-3 mb-md-5">
                      Connect
                    </h6>
                    {/* List */}
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <a
                          className="text-white-60"
                          href="mailto:start@plinks.io"
                        >
                          Help Center
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white-60"
                          href="mailto:start@plinks.io"
                        >
                          Press
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white-60"
                          href="mailto:start@plinks.io"
                        >
                          Feedback
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 col-md">
                    {/* Heading */}
                    <h6 className="text-uppercase text-white mb-3 mb-md-5">
                      Legal
                    </h6>
                    {/* List */}
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <a className="text-white-60" href="#!">
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a className="text-white-60" href="#!">
                          Terms
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Home);
