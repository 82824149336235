import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';

import BarChartExposure from '../../../charts/BarChartExposure';
import { DashboardContext } from '../../../shared/DashboardContext';
import { AuthenticationContext } from '../../../shared/AuthenticationContext';
import TableMemberComponent from '../../../components/TableMember';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';

import '../../../../assets/css/primary.theme.css';

const options = [
  { value: 'grocery', label: 'Cash Register 1' },
  { value: 'restaurant', label: 'Cash Register 2' },
  { value: 'pizza-shop', label: 'Front Door' },
  { value: 'bakery', label: 'Loading Dock' },
];

class Selector extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log('Option selected:', this.state.selectedOption),
    );
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
        isMulti
        placeholder="Grant access"
      />
    );
  }
}

const SiteAdminMembers = () => {
  const [open, openBottomSheet] = useState(false);
  return (
    <AuthenticationContext.Consumer>
      {(auth) => (
        <DashboardContext.Consumer>
          {({ organizationId, siteId, setOrganization, setSite }) => (
            <>
              <SwipeableBottomSheet
                open={open}
                onChange={openBottomSheet.bind(this)}
                marginTop="20"
                style={{ zIndex: '10' }}
                bodyStyle={{
                  borderTopLeftRadius: '1rem',
                  borderTopRightRadius: '1rem',
                }}
                fullScreen
              >
                <div className="PrimaryTheme">
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-8 py-lg-8">
                        <h3 className="mb-4">Invite a team member</h3>
                        <p className="mb-6  text-muted">
                          Members can anonymously submit temperature and symptom
                          reports. As a site manager you can grant members
                          permissions to QR codes such as scanning, editing,
                          disabling.
                        </p>
                        <form className="mb-4">
                          <div className="form-label-group mb-6">
                            <input
                              type="text"
                              className="form-control form-control-flush"
                              placeholder="Add an email"
                            />
                            <label htmlFor="cardEmail">Add an email</label>
                          </div>
                          <Selector />
                        </form>
                        <button
                          className="btn btn-block btn-primary mt-6 lift"
                          onClick={() => openBottomSheet(false)}
                        >
                          Invite team member
                        </button>
                        <div className="text-center">
                          <button
                            className="btn"
                            onClick={() => openBottomSheet(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwipeableBottomSheet>
              <div
                className="PrimaryTheme"
                style={{ overflowX: 'hidden', minHeight: '100vh' }}
              >
                <section>
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-12 pt-4 py-lg-8">
                        <div className="row">
                          <div className="col">
                            <h2 className="mb-4">
                              <Link
                                to="/dashboard/business"
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit',
                                }}
                              >
                                <i className="fe fe-arrow-left mr-2" />
                              </Link>
                              Members
                            </h2>
                          </div>
                          <div className="col-auto">
                            <span className="badge badge-pill badge-primary-soft">
                              <span className="h6 text-uppercase font-weight-bold">
                                Admin only
                              </span>
                            </span>
                          </div>
                        </div>
                        <h4 className="text-muted ml-4">
                          311 Winthrop Drive, Cambridge MA 02138
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 pt-4 pb-0">
                        <div className="row">
                          <div className="col">
                            <h3 className="card-title text-body mb-0 ml-4">
                              Average Site Member Exposure
                            </h3>
                          </div>
                          <div className="col-auto">
                            <i
                              className="fe fe-info"
                              onClick={() => openBottomSheet(true)}
                            />
                          </div>
                        </div>
                        <BarChartExposure />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 p-3">
                        <TableMemberComponent />
                      </div>
                      <div className="col-12 col-lg-6 pb-3 px-3">
                        <div
                          className="card"
                          style={{
                            backgroundColor: 'transparent',
                            border: '1px #c1c1c1 dashed',
                          }}
                        >
                          <div className="card-body text-center">
                            <button
                              className="btn btn-primary my-4"
                              onClick={() => openBottomSheet(true)}
                            >
                              <i className="fe fe-plus mr-2" />
                              New member for this site
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </DashboardContext.Consumer>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default withRouter(SiteAdminMembers);
