import React from 'react';
import F2 from '@antv/f2';

class BarChartExposure extends React.Component {
  componentDidMount() {
    const data = [
      {
        day: 'Mon',
        avgExposure: 2,
      },
      {
        day: 'Tue',
        avgExposure: 2,
      },
      {
        day: 'Wed',
        avgExposure: 3,
      },
      {
        day: 'Thu',
        avgExposure: 4,
      },
      {
        day: 'Fri',
        avgExposure: 4,
      },
      {
        day: 'Sat',
        avgExposure: 4,
      },
      {
        day: 'Sun',
        avgExposure: 4,
      },
    ];
    const chart = new F2.Chart({
      id: 'container-4',
      pixelRatio: window.devicePixelRatio,
    });
    this.chart = chart;
    chart.source(data, {
      avgExposure: {
        tickCount: 5,
        min: 0,
        max: 10,
      },
    });
    chart.tooltip({
      showItemMarker: false,
      onShow: function onShow(ev) {
        const items = ev.items;
        items[0].name = null;
        items[0].name = items[0].title;
      },
    });
    chart.interval().position('day*avgExposure').color('#335fea');
    chart.render();
  }
  componentWillUnmount() {
    // Make sure to destroy Typed instance on unmounting
    // to prevent memory leaks
    this.chart.destroy();
  }
  render() {
    return <canvas id="container-4" width="400" height="260"></canvas>;
  }
}

export default BarChartExposure;
