// Temporary disable the eslint run `no-undef` because of the global $ (jQuery)
/* eslint no-undef: 0 */

import React, { useState } from 'react';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import '../../../assets/css/primary.theme.css';

const Signup = () => {
  const history = useHistory();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const onSubmit = (e) => {
    e.preventDefault();

    axios({
      method: 'post',
      url: '/api/auth/register',
      data: {
        name,
        email,
        password,
      },
    })
      .then(() => {
        history.push('/signin');
      })
      .catch((e) => {
        console.error('Signup failed: ', e);
      });
  };

  return (
    <div className="PrimaryTheme">
      <section className="section-border border-primary">
        <div className="container-sm d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">
              <h1 className="mb-0 font-weight-bold text-center"> Sign up </h1>
              <p className="mb-6 text-center text-muted">
                {' '}
                Simplify your exposure mitigation
                <br />
                workflow in minutes.{' '}
              </p>
              <form className="mb-6" onSubmit={onSubmit}>
                <div className="form-label-group">
                  <input
                    type="text"
                    className="form-control form-control-flush"
                    id="cardName"
                    placeholder="Full name"
                    onChange={(e) => {
                      setName(e.target && e.target.value);
                    }}
                  />
                  <label htmlFor="cardEmail">Full name</label>
                </div>
                <div className="form-label-group">
                  <input
                    type="email"
                    className="form-control form-control-flush"
                    id="cardEmail"
                    placeholder="Email"
                    onChange={(e) => {
                      setEmail(e.target && e.target.value);
                    }}
                  />
                  <label htmlFor="cardEmail">Email</label>
                </div>
                <div className="form-label-group">
                  <input
                    type="password"
                    className="form-control form-control-flush"
                    id="cardPassword"
                    placeholder="Password"
                    onChange={(e) => {
                      setPassword(e.target && e.target.value);
                    }}
                  />
                  <label htmlFor="cardPassword">Password</label>
                </div>
                <div className="mt-6">
                  <button
                    className="btn btn-block btn-primary lift"
                    type="submit"
                  >
                    Signup
                  </button>
                </div>
              </form>
              <p className="mb-0 font-size-sm text-center text-muted">
                Already have an account?
                <Link to="/login"> Log in</Link>.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
