import React from 'react';
import F2 from '@antv/f2';

class Sparkline extends React.Component {
  componentDidMount() {
    const data = [
      {
        time: '2016-08-08 00:00:00',
        value: 10,
        type: 'checkIn',
      },
      {
        time: '2016-08-08 00:10:00',
        value: 22,
        type: 'checkIn',
      },
      {
        time: '2016-08-08 00:30:00',
        value: 16,
        type: 'checkIn',
      },
      {
        time: '2016-08-09 00:35:00',
        value: 26,
        type: 'checkIn',
      },
      {
        time: '2016-08-09 01:00:00',
        value: 12,
        type: 'checkIn',
      },
      {
        time: '2016-08-09 01:20:00',
        value: 26,
        type: 'checkIn',
      },
      {
        time: '2016-08-10 01:40:00',
        value: 18,
        type: 'checkIn',
      },
      {
        time: '2016-08-10 02:00:00',
        value: 26,
        type: 'checkIn',
      },
      {
        time: '2016-08-10 02:20:00',
        value: 12,
        type: 'checkIn',
      },
    ];
    const chart = new F2.Chart({
      id: 'container-5',
      pixelRatio: window.devicePixelRatio,
      padding: 10,
    });
    this.chart = chart;
    chart.source(data, {
      time: {
        type: 'timeCat',
        tickCount: 3,
        mask: 'hh:mm',
        range: [0, 1],
      },
      value: {
        tickCount: 3,
        formatter: function formatter(ivalue) {
          return ivalue + '%';
        },
      },
    });
    chart.axis(false);
    chart.line().position('time*value').color('#335fea');

    chart.render();
  }
  componentWillUnmount() {
    // Make sure to destroy Typed instance on unmounting
    // to prevent memory leaks
    this.chart.destroy();
  }
  render() {
    return <canvas id="container-5" width="150" height="50"></canvas>;
  }
}

export default Sparkline;
